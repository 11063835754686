// Heebo Font Family
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;700;800;900&display=swap');
@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

nav.navbar.menu-navbar {
  box-shadow: none;
}

.ss-custom-slider {
  .slick-dots {
    margin-bottom: 155px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.main-seeStories * {
  text-align: left !important;
}

.main-seeStories {
  .container {
    max-width: 1128px;
  }

  .pink-text {
    color: $primary-brand-3;
    margin-bottom: 5rem;
  }

  .different-font-style {
    font-family: Homenaje;
    margin-bottom: 3.2rem;

    @media only screen and (max-width:$md){
      margin-bottom: 1.6rem;
    }

  }

  .different-font-style-2 {
    font-family: Koulen;
    width: 100%;
    font-weight: 400;
  }

  .text-style {
    color: $white;
    margin-bottom: 8rem;
  }

  .subtitle-styles {
    margin-bottom: 1.6rem !important;

  }

  .subtitle-styless {
    margin-bottom: 3.2rem;
    max-width: 64.8rem;
    margin-top: 1.6rem;

  }

  .subtitle-styles-1 {
    max-width: 59.5rem;
  }

  // Inpage Global
  section {
    position: relative;
  }

  .styling {
    margin-bottom: 8rem;
  }



  // .fs-16 {
  //   font-size: 16px !important;
  // }

  .banner-sec {
    min-height: 1024px;
    display: flex;
    align-items: flex-end;
    border-radius: 0px 0px 30px 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 12;
    box-shadow: 60px 60px 77px 0px $dark-1;




    .watch-video-button {

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: transparent;
      border: none;
      outline: none;
      color: $white;
      border: none;
      background: none;


      .button-icon-container {

        width: 4.8rem;
        height: 4.8rem;
        background-color: $white;
        border-radius: 100%;
        display: flex;
        align-items: center;
        transition: all 0.3s ease;


        .image-button {

          display: block;
          margin: auto;

          .icon-style {
            fill: $dark-1;
            color: $dark-1;

            height: 15px;
          }


        }


      }

      &:hover {
        .button-icon-container {
          background-color: $dark-1;
        }

        .image-button {

          display: block;
          margin: auto;

          .icon-style {
            fill: $white;
            color: $white;

            height: 15px;
          }
        }
      }
    }

    // z-index: 1;
    .subtitle-styles {
      max-width: 75.8rem;
      margin-top: 1.6rem;
    }

    &::before {
      content: ' ';
      position: absolute;

      height: 496px;
      flex-shrink: 0;
      border-radius: 496px;
      opacity: 0.24;
      background: $border-gradient-end;
      filter: blur(180.5px);
      left: -248px;
      bottom: -248px;
      z-index: -1;
    }

    .description {
      height: 100%;
      padding-bottom: 200px;

      img {
        position: absolute;
        top: 200px;
        right: 48px;
      }


    }


  }

  .the-unforgivables-sec {
    padding-top: 80px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    z-index: 10;
    background-color: $dark-1;
    padding-bottom: 80px;

    .tu-subtitle-text {
      margin-bottom: 80px;
    }

    .description {

      .border-top {
        border-color: rgba($grey-3, 0.4) !important;
        margin: 80px 0;
      }

      h4 {
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 80px;

        span {
          color: $primary-brand-3;
          font-size: inherit;
          line-height: 24px;
          /* 120% */
        }
      }

      ul {
        position: relative;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          color: $border-gradient-end;
          font-family: Heebo;

          /* 142.857% */
        }
      }

      h6 {
        margin-bottom: 16px;
      }
    }
  }

  .the-unforgivables-secc {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-top: -100px;
    background-color: $dark-1;

    .text-style {
      margin-top: 80px;
    }
  }

  .header-02-styles {
    position: relative;
    margin-top: 163px;
    height: 1024px;
    z-index: 1;

    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      height: 100%;
      width: 100%;
      padding-bottom: 136px;
    }

    .main-heading {
      margin-top: 8px;
      margin-bottom: 16px;
      text-align: left;
    }

    .sub-heading:nth-last-child(2) {
      margin-bottom: 32px;
    }

    .buttons {
      display: flex;
      align-items: flex-start;
      gap: 10px;
    }

    .buttonStyle {
      padding: 16px 32px;
      border: 2px solid $border-gradient-end;

      border-radius: 50px;
      color: $white;
    }
  }

  .slider-sec {
    .text-gray {
      color: $dark-3;
    }
  }

  .haderbach-sec {
    background-size: contain;
    min-height: 600px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    background-position: center;
    padding-left: 50px;

    .subtitle-styles {
      margin-bottom: 5px;
    }

    .d-link {
      position: relative;
      color: $dark-4;

      text-decoration: none;

      i {
        display: inline-block;
        margin-left: 4px;
        transition: 0.1s;
      }

      &:hover {
        color: $primary-brand-3;

        i {
          padding-left: 5px;
        }
      }
    }
  }

  .custom-slide {
    position: relative;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 16px;
      padding: 0px !important;
    }

    div {
      position: absolute;
      bottom: 150px;
      left: 151px;
    }
  }


}

.eclipse {
  position: absolute;
  width: 496px;
  height: 496px;
  flex-shrink: 0;
  border-radius: 496px;
  opacity: 0.24;
  background: $border-gradient-end;
  filter: blur(180.5px);
  transform: translate(-34%, -50%);
  z-index: -1;

  @media only screen and (max-width: $sm) {

    width: 234px !important;
    height: 234px !important;
    flex-shrink: 0;
    fill: $border-gradient-end;
    opacity: 0.32;
    filter: blur(99.5px);

  }
}

//modal stylings
.modal-content {
  background-color: transparent;
  border: none;
  outline: none;
}

.video-container {
  position: relative;
  box-shadow: 50px 50px 50px 0px rgba($dark-1, 0.5);
  /* Add black shadow */
  // border: 1px solid yellow;
  border-radius: 20px;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;


}

.video-styling {
  max-width: 100%;
  max-height: 100%;
  border-radius: 20px;
  border: none;
  outline: none;

}

.icon-style {
  transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
  color: $white;

  max-height: 15px;
}

.button-styles {
  position: absolute;
  top: 3.2rem;
  right: 3.2rem;
  background-color: rgba($dark-1, .5);
  border: 2px solid rgba($grey-4, 0.2);
  outline: rgba($grey-4, .1);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: 5px 7px 18px rgba($grey-2, .1), 0 8px 21px rgba($grey-1, .15);
  z-index: 10;
  transition: all 0.3s ease;



  &:hover {
    background-color: $white;

    .icon-style {
      transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
      color: $dark-1;

      height: 15px;
    }
  }
}

.center-play-icon-container {
  position: absolute;
  transition: transform 0.3s ease;
  width: 8.8rem;
  height: 8.8rem;
  border-radius: 50%;
  background-color: rgba($dark-1, .5);
  box-shadow: inset 0 0 0 1px rgba($grey-4, .2);
  border: 2px solid rgba($grey-4, 0.2);

  z-index: 10;

  .play-icon-style {
    transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
    color: $white;

    height: 30px;
    fill: $white;




  }

  &:hover {
    transform: scale(1.04);
  }

}

@media (max-width: 992px) {
  .subscription-sec {
    min-height: 1024px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 30px 30px;
    background: radial-gradient(122.71% 245.32% at 50% -145.32%,
        $dark-4 0%,
        $dark-1 85.46%,
        $dark-1 100%);
  }

  .subscription-sec .row {
    justify-content: center;
    text-align: center;
  }

  .banner-sec-landingPage {
    .banner-sec .subtitle-styles {

      letter-spacing: 0.5px;
      margin-bottom: 30px;
    }

    .banner-sec img.mobile {
      display: none;
    }

    .banner-sec img.desktop {
      display: block;
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (max-width: 1024px) {
  nav.navbar {
    max-width: 100% !important;
    width: 100% !important;
  }

  .main-seeStories {
    .container {
      max-width: 900px;
    }

    .main-headingg {
      overflow: hidden;

    }

    .banner-sec {


      .subtitle-styles {

        margin-bottom: 30px;
      }
    }





    .main-seeStories .banner-sec-landingPage .banner-sec .img-box::before {
      display: none;
    }

    .haderbach-sec {
      background-position: right;
    }
  }
}

@media only screen and (max-width: 991px) {
  .main-seeStories {
    * {
      text-align: left !important;
    }

    .row.justify-content-center {
      justify-content: left !important;
    }

    .container {
      max-width: 100% !important;
      padding: 0 16px !important;
    }

    .banner-sec {
      min-height: 926px;

      .subtitle-styles {

        margin-bottom: 30px;
      }

      img {
        border-radius: 20px;
        width: 260px;
      }

      .description {
        padding-bottom: 40px;

        img {
          position: unset;
          top: unset;
          right: unset;
          margin-bottom: 20px;
        }

        p {
          display: block;
          width: 100%;
          margin: 0;
        }
      }
    }

    .subtitle-styles {

      margin-bottom: 24px;
    }

    section {

      .description {
        padding-top: 215px;
        margin-bottom: 80px !important;

      }
    }

    .the-unforgivables-sec {


      .col-md-4 {
        order: 2;

        .description {
          margin-bottom: 0px !important;

          .subtitle-styles span {
            color: $primary-brand-3;
            font-size: inherit;
            line-height: 2.4rem;
          }

          >.border-top {
            border-color: rgba($grey-1, 0.4) !important;
            margin: 25px 0px;
          }

          p {
            margin-bottom: 20px;
          }

        }
      }

      .col-md-6 {
        .description>.border-top {
          display: none;
        }
      }

      .description {
        .subtitle-styles span {
          color: $primary-brand-3;
          font-size: inherit;
          line-height: 24px;
        }
      }
    }

    .header-02-styles {
      margin-top: 80px;



      .text-wrapper {
        padding-bottom: 100px !important;
      }


    }

    .creative__collaboration {
      position: relative;
      padding-top: 80px;
    }

    .gallery-view {
      gap: 15px;
      margin: 0 0px 80px 0px;

      .gallery-image {
        flex-basis: 100%;
      }
    }

    .slider-sec .text-gray {
      display: block;
    }

    .haderbach-sec {
      background-size: cover;
      border-radius: 30px;
      min-height: 823px;
      background-position: center !important;
      align-items: end;
      padding-left: 0px;


    }

    //   Landing Page
    .works-of-art {
      background-position: right;
      background-size: cover;
      display: flex;
      align-items: flex-end;
    }

    .create-your-series {
      padding: 80px 0 80px 0;
    }

    section .description {
      padding-top: 0px;
    }

    .finding-your-team {
      padding: 0px;
    }

    .guid-your-vision {
      position: relative;
      padding: 80px 0 80px 0;
    }

    .meets-structure {
      padding: 0px 0;
    }

    .haderbach-sec img {
      margin-bottom: 40px;
    }

    .blue-section-styles {
      height: 380px;
    }

    .blue-section .section-text-style {
      text-align: center !important;
      width: 100%;

    }

    .blue-section * {
      text-align: center !important;

    }

    .custom-slide {
      img {
        width: 428px;
        height: 832px;
        flex-shrink: 0;
      }

      div {
        position: absolute;
        bottom: 15px;
        left: 15px;
      }
    }
  }
}