@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

.password-change-success-container {
    display: flex;

    .pcs-left-container {
        background: linear-gradient(-45deg, rgba(183, 10, 128, 0.67), rgba(33, 64, 154, 0.71), rgba(4, 30, 45, 1), );
        width: 48rem;
        height: 100vh;
    }

    .pcs-right-container {
        display: grid;
        grid-template-rows: min-content;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        .pcs-right-container-align-center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 55.2rem;
            height: 100vh;
            text-align: center;

            .logo {
                height: 4rem;
                width: 4rem;
                margin: 2rem auto;
                opacity: 0.7;
            }

            .success-text {
                margin: 1rem 0 1rem 0;
            }

            .success-sub-text {
                margin-bottom: 5rem;
                color: $dark-3;
                text-align: center;
            }

            .success-continue-button {
                color: $white;
                width: 100%;
                border-radius: 20rem;
                position: relative;
            }
        }

        @media only screen and (max-width: $xs) {
            .pcs-right-container-align-center {
                width: unset;
                padding: 0 1rem;
            }
        }
    }
}

@media only screen and (max-width: $lg) {
    .pcs-left-container {
        display: none;
    }
}

@media only screen and (max-width: $md) {
    .password-change-success-container {
        flex-direction: column;
    }
}