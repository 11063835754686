@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';


.subtitle-left-01 {
  // width: 45.6rem;
  text-align: left;
  color: $primary-brand-3;
  word-wrap: break-word;
}

.subtitle-left-03 {
  max-width: 55.2rem;
  text-align: left;
  color: $primary-brand-3;
  word-wrap: break-word;
}

.subtitle-left-02 {
  max-width: 112.8rem;
  text-align: left;

  word-wrap: break-word;

  span {
    color: $primary-brand-3;
  }
}

.subtitle-center-01 {

  text-align: center;
  color: $primary-brand-3;
  word-wrap: break-word;

  @media only screen and (max-width:$sm) {
    text-align: left !important;
  }
}

.subtitle-center-02 {

  text-align: center;

  word-wrap: break-word;

  @media only screen and (max-width:$sm) {
    text-align: left !important;
  }
}

.subtitle-styles {
  max-width: 55.2rem;
}

.section-01-styles {

  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: $dark-1;
  padding-bottom: 5px;
  box-shadow: 30px 60px 90px 0 rgba($dark-1, 0.5);

}

.subtitle-stylingg {
  max-width: 93.6rem;
}

.eclipse {
  position: absolute;
  width: 496px;
  height: 496px;
  flex-shrink: 0;
  border-radius: 496px;
  opacity: 0.24;
  background: $grey-1;
  filter: blur(180.5px);
  transform: translate(-34%, -50%);
}

.reel-growth-text {
  margin-bottom: 20px;
}

span {
  font-size: inherit;
}

.percentage-text-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.text-percentage-section {
  margin-top: 160px;
  margin-bottom: 160px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width:$md) {
    margin-bottom: 10px;
  }

  .text-section {
    margin-right: 0;
    margin-bottom: 20px;

    .title-02 {
      text-align: left;
      width: 100%;

    }

    .subtitle-02 {
      text-align: left;
      width: 100%;
    }

    .subtitle-styling {
      max-width: 107.6rem;
    }

    .para-percent {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      gap: 15rem;
      height: 100%;

      @media only screen and (max-width:$sm) {
        flex-direction: column !important;
        gap: 2rem;
      }
    }
  }


}

.blue-section {
  border-radius: 0;
  margin-top: -20px;
  z-index: -999;

  @media only screen and (max-width:$md) {
    height: 100%;
  }
}

.hit-container {
  margin-top: 8rem;

  @media only screen and (max-width:$md) {
    margin-top: 0;
  }
}

.blue-section-01-styles {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  z-index: 999;

  @media only screen and (max-width:$sm) {
    .text-button-container {
      align-items: flex-start;
    }

    .text-wrapper {
      align-items: flex-start;

      .title-center {
        text-align: left;
      }
    }
  }
}

.blue-section-styles {
  height: 500px;
}

.text-percentage-section {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  z-index: 999;

  .text-section {
    margin-top: 100px;
  }
}

.image-box {
  max-width: 100%;
  max-height: 960px;
  margin-top: -20px;
  z-index: -999;
}

@media (min-width: 992px) {
  .percentage-text-container {
    flex-direction: row;
  }

  .text-percentage-section {
    flex-direction: row;

    .text-section {
      margin-right: 120px;




    }
  }
}