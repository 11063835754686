@import '../../../../styles/colors.scss';

.main-containerr {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  position: relative;
  overflow: hidden;

  .header-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  .sub-heading {
    color: $white;
  }

  .main-heading {
    color: $white;
    
  }
}
