@import '../../../../styles/colors.scss';

// Accordion Container
.accordion-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.arrow-icon {
  transition: transform 1s ease-in;

}

.chevron-icon {
  height: 16px;
}

.arrow-icon-open {
  transform: rotate(180deg);
}


.title-icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

// Accordion Heading
.accordion-heading {
  width: 100%;
  word-wrap: break-word;
  margin-bottom: 64px;
}

// Accordion Design
.accordion-design {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Accordion Divider
.accordion-divider {
  border-bottom: 1px solid $border-gradient-start;
  width: 100%;
}

// Accordion Title Divider Container
.accordion-title-divider-container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 32px;
}

// Accordion Content
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  width: 100%;

  &.open {
    max-height: 100vh;
  }
}

.accordion-content {
  margin-bottom: 32px;
}

.content {
  height: max-content;
  color: $dark-4;
  animation: gradient-reveal 0.4s ease;
  opacity: 1;
}

// Arrow Icon
.arrow-icon {
  transition: transform 0.2s ease;


  &.open {
    transform: rotate(-180deg);
  }
}

.accordion-title {
  color: $white;
}