@import '../../../styles/colors.scss';

.common-questions-container {
  margin-top: 200px;
  border-radius: 30px;
  border: 1px solid rgba($border-gradient-end ,10%);
  padding: 96px 117px;
  background-color: $accent-1;

  @media only screen and (max-width: 770px) {
    padding: 96px 40px;
  }

  @media only screen and (max-width: 500px) {
    padding: 96px 20px;
  }
}

.main-heading {
  text-align: center;
  color: $white;
}

.accordions {
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.accordions-containers {
  display: flex;
  flex-direction: column;
}

// Accordion Heading
.accordion-heading {
  width: 100%;
  color: $white;
  
  word-wrap: break-word;

  
}

.blue-section-styles {
  height: 662px;
  background: radial-gradient(
    122.71% 245.32% at 50% 245.32%,
    $secondary-brand-3 0%,
    $dark-1 85.46%,
    $dark-1 100%
  );
  // border: 1px solid yellow;
}

.sectionText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .textStyle {
    max-width: 75.7rem;
    
    text-align: center;
    background: linear-gradient(
      92deg,
      $white 9.82%,
      rgba($white, 0) 160.12%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
