@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';


.super-producers {
  // box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  // grid-template-areas:
  //   'header '
  //   'section-02 '
  //   'section-03 ';

  // overflow-x: hidden;
}

// common class
.textStyle {
  font-weight: 400;
}

.subtitle-styling {
  max-width: 26.6rem;
}

.sub-heading {
  color: $white;
  max-width: 112.8rem;
  line-height: 24px;
}

.sub-headingg {
  color: $white;
  max-width: 96.2rem;
  line-height: 24px;
}

.main-heading {

  .heading-style {
    max-width: 112.8rem;
  }

  .s2-mainHeading {
    color: $white;
    max-width: 112.8rem
  }



  /* Main Header */

  line-height: 88px;
  /* 100% */
  // width: 1128px;
  // text-align: left;
}

.spanStyle {
  color: $primary-brand-3;
}

.section-01 {
  // grid-area: header;
  height: 1024px;
  z-index: 10;

  .bg-image {
    @media only screen and (max-width:$md) {
      width: 772px;
    }
  }

  @media only screen and (max-width:$md) {
    height: 892px;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    padding-bottom: 136px;

    @media only screen and (max-width:$md) {
      gap: 1rem;
      padding-bottom: 30px;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }

  .main-heading {
    margin-top: 8px;
    margin-bottom: 16px;
    text-align: left;


    @media only screen and (max-width:$md) {
      margin: 0;
    }
  }

  @media only screen and (max-width: 1200px) {
    .main-heading {
      width: auto;
    }
  }

  @media only screen and (max-width: 800px) {
    .main-heading {

      background: linear-gradient(92deg,
          $white 9.82%,
          rgba($white, 0) 160.12%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  @media only screen and (max-width: 550px) {
    .main-heading {

      background: linear-gradient(92deg,
          $white 9.82%,
          rgba($white, 0) 160.12%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .sub-heading {
      color: $white;

    }

    .buttons {
      flex-direction: column;
    }
  }

  .sub-heading:nth-last-child(2) {
    margin-bottom: 32px;

    @media only screen and (max-width:$md) {
      margin-bottom: 10px;
    }
  }

  .buttons {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    @media only screen and (max-width:$md) {
      row-gap: 15px;
    }
  }

  .buttonStyle {
    padding: 16px 32px;
    height: 56px;
    border-radius: 50px;
    color: $white;
  }
}

.section-02 {
  // grid-area: section-02;
  position: relative;
  // overflow: hidden;
  margin-top: -40px;
  // z-index: -2;

  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    justify-content: flex-start;
    width: 100%;
    padding-top: 156px;
    padding-bottom: 120px;

    @media only screen and (max-width:$md) {
      padding-top: 100px;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      padding-bottom: 90px;
    }
  }

  .main-heading {
    text-align: left;
    max-width: 68.9rem;
    margin: 0;
  }

  @media only screen and (max-width: 800px) {
    .main-heading {
      color: $white;

    }

    .gradientStyle1 {
      display: none;
    }

    .gradientStyle2 {
      display: none;
    }
  }

  @media only screen and (max-width: 500px) {
    .buttons {
      flex-direction: column;
    }

    .main-heading {

      background: linear-gradient(92deg,
          $white 9.82%,
          rgba($white, 0) 160.12%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .sub-heading {
      color: $white;

    }
  }

  @media only screen and (max-width: 500px) {
    .main-heading {
      color: $white;

    }
  }

  .eclipse {
    position: absolute;
    width: 496px;
    height: 496px;
    flex-shrink: 0;
    border-radius: 496px;
    opacity: 0.24;
    background: $grey-1;
    filter: blur(180.5px);
    transform: translate(-34%, -50%);
    z-index: -10;

    @media only screen and (max-width:$md) {
      width: 200px;
      height: 427px;
      filter: blur(92.5px);
      opacity: 0.27;
    }


  }

  .image-container {
    position: relative;

    @media only screen and (max-width:$md) {
      padding: 0 1.6rem;

      img {

        border-radius: 1.5rem;
        height: 210px !important;
      }
    }
  }

  .gradientStyle1 {
    width: 1585px;
    height: 496px;
    flex-shrink: 0;
    border-radius: 1585px;
    opacity: 0.24;
    background: $border-gradient-end;
    filter: blur(180.5px);
    z-index: -1;
    margin-bottom: -450px;
  }

  .gradientStyle2 {
    position: absolute;
    width: 1585px;
    height: 496px;
    flex-shrink: 0;
    border-radius: 1585px;
    opacity: 0.24;
    background: $primary-brand-1;
    filter: blur(180.5px);
    margin-top: -450px;
    z-index: -1;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text-wrapper-02 {
    display: flex;
    flex-direction: column;
    // justify-content: right;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    padding-top: 140px;
    padding-bottom: 52px;

    @media only screen and (max-width:$md) {
      padding-top: 90px;
      padding-bottom: 40px;
    }
  }

  .text-wrapper-03 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-end;
    text-align: right;
    width: 100%;
    padding-bottom: 52px;

    @media only screen and (max-width:$md) {
      display: none;
    }
  }

  .main-heading-02 {
    color: $white;

  }

  .header-02-styles {
    position: relative;
    margin-top: 163px;
    height: 1024px;
    z-index: 1;

    @media only screen and (max-width:$md) {
      margin-top: 90px;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      height: 100%;
      width: 100%;
      padding-bottom: 136px;
    }

    .main-heading {
      margin-top: 8px;
      margin-bottom: 16px;
      text-align: left;
    }

    .sub-heading:nth-last-child(2) {
      margin-bottom: 32px;
    }

    .buttons {
      display: flex;
      align-items: flex-start;
      gap: 10px;
    }

    .buttonStyle {
      padding: 16px 32px;
      border: 2px solid $border-gradient-end;

      border-radius: 50px;
      color: $white;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  @media only screen and (max-width: 1200px) {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, min-content);
      align-items: center;
      justify-content: center;
      width: 360px;
      gap: 20px;
    }
  }

  @media only screen and (max-width: 800px) {
    .grid-container {
      display: grid;
      grid-template-columns: min-content;
      align-items: center;
      justify-content: center;
      width: 360px;
      gap: 20px;
    }
  }
}

.section-03 {
  // grid-area: section-03;
  margin-top: 150px;

  .card-container {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    justify-content: center;
    gap: 56px;
  }

  @media only screen and (max-width: 1300px) {
    .card-container {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      align-items: center;
      justify-content: center;
      gap: 56px;
    }
  }

  @media only screen and (max-width: 900px) {
    .card-container {
      display: grid;
      grid-template-columns: repeat(1, max-content);
      align-items: center;
      justify-content: center;
      gap: 56px;
    }
  }

  .common-questions-container {
    margin-top: 160px;
    border-radius: 30px;
    border: 1px solid rgba($secondary-brand-4, 10%);
    padding: 96px 117px;
    background: $accent-1;

    @media only screen and (max-width: 770px) {
      padding: 96px 40px;
    }

    @media only screen and (max-width: 500px) {
      padding: 96px 20px;
    }
  }

  .main-headingg {
    text-align: center;
    margin-bottom: 48px;
    color: $white;

  }

  .accordions {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }

  .accordions-containers {
    display: flex;
    flex-direction: column;
  }

  // Accordion Heading
  .accordion-heading {
    width: 100%;
    color: $white;

    word-wrap: break-word;


  }

  .eclipse1 {
    position: absolute;
    width: 496px;
    height: 649px;
    flex-shrink: 0;
    border-radius: 496px;
    opacity: 0.24;
    background: $border-gradient-end;
    filter: blur(180.5px);
    transform: translateX(-50%);
    left: 50%;
    z-index: -1;
    margin-top: 80px;
  }

  @media only screen and (max-width: 800px) {
    .eclipse1 {
      display: none;
    }
  }

  .plain-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 160px;
    border-radius: 30px;
    border: 1px $secondary-brand-4;
    padding: 96px 117px;
    background: $accent-1;
    gap: 32px;
    height: 538px;
    position: relative;
    z-index: 1;

    @media only screen and (max-width:$md) {
      height: 0;
      padding: 110px 40px !important;
      margin-top: 70px;
    }

    .text {
      color: $white;
      text-align: center;


    }

    @media only screen and (max-width: 770px) {
      padding: 96px 40px;
    }

    @media only screen and (max-width: 500px) {
      padding: 96px 20px;
    }
  }

  .blue-section-styles {
    height: 662px;
    width: 100%;
    z-index: -10;
    background: radial-gradient(122.71% 245.32% at 50% 245.32%,
        $secondary-brand-3 0%,
        $dark-1 85.46%,
        $dark-1 100%);


    // border: 1px solid yellow;
    .blue-section-text-center {
      max-width: 93.2rem;
      background: linear-gradient(92deg,
          $white 9.82%,
          rgba($white, 0) 160.12%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

    }

    @media only screen and (max-width:$md) {
      height: 400px;
    }
  }
}

//
button.subscribe-btn {
  color: #FFF;
  font-family: Heebo;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 48px;
  background: $primary-brand-1;
  padding: 15px 32px;
  border: 1px solid $primary-brand-1;
  transition: 0.3s;
  outline: none;
  height: 64px;
  position: absolute;
  right: 0px;
}

button.subscribe-btn:hover {
  background-color: #b70a80;
}

.row {
  justify-content: center;
}

.desktop_view {
  display: block;

  @media only screen and (max-width:$md) {
    display: none !important;
  }
}

.mobile_slider {
  position: relative;
  z-index: 50;
  display: none;
  padding: 0;
  margin: 0;



  .image-text-style {
    justify-content: flex-end;
    padding-right: 70px !important;
    width: 100%;
  }

  .slick-slider {
    margin: 0 !important;
    padding: 0;

    img {
      padding: 0 !important;
    }
  }

  @media only screen and (max-width:$md) {
    display: block !important;
  }
}

section.parent {
  view-timeline-name: --section;
  width: 74.4rem;
  --content-size: 100%;

  form input {
    height: 65px;
  }

  @media only screen and (max-width: $lg) {
    width: 55.4rem;
  }

  @media only screen and (max-width: $md) {
    width: 45.4rem
  }

  @media only screen and (max-width: $sm) {
    width: 34.4rem;
  }

  /* CTA Specific stuff goes here.... */
  .cta {
    /* Activates showing the CTA */
    animation: activate both linear, activate linear reverse;
    animation-timeline: --section, view();
    animation-range: entry, cover 50%;
  }

  /* Word */
  .cta span:nth-of-type(1) {
    opacity: var(--scale, 0);
    transition: opacity 0.2s 0.6s;
    white-space: nowrap;
  }

  /* The icon holder */
  .cta span:nth-of-type(2) {
    display: grid;
    place-items: center;
  }

  .cta .input-container {
    --elastic: linear(0, 0.4789 7.06%, 0.7612 12.38%,
        0.8693 15.13%, 0.9538 17.95%,
        1.0165 20.89%, 1.059 24.04%,
        1.0829 27.58%, 1.0884 31.64%,
        1.0769 36.44%, 1.0202 50.6%,
        1.0005 59.62%, 0.9945 70.6%, 1);
    display: grid;
    grid-template-columns: 0 36px;
  }

  @keyframes activate {
    0% {
      --active: 0;
    }

    100% {
      --active: 1;
    }
  }
}

/* Use style queries to dictate the timing, etc. */
@container style(--active: 0) {
  section.parent {
    .cta__content::before {
      scale: 0;
      opacity: 0;
      transition: scale 0.5s var(--back);
    }

    .cta .input-container {
      scale: 0;
      transition: scale 0.2s 0.7s, grid-template-columns 0.5s 0.2s var(--elastic);
    }

    .cta__content span:first-of-type {
      opacity: 0;
      transition: opacity 0.2s 0s;
    }

    .cta__content span:last-of-type {
      scale: 0;
      opacity: 0;
      transition: scale 0.2s, opacity 0.2s;
    }
  }
}

@container style(--active: 1) {
  section.parent {
    .cta__content::before {
      scale: 0.99;
      opacity: 1;
      transition: scale 0.5s var(--back);
    }

    .cta .input-container {
      scale: 1;
      transition: scale 0.2s 0s, grid-template-columns 0.5s 0.6s var(--elastic);
      grid-template-columns: var(--content-size, auto) 0px;
    }

    .cta__content span:first-of-type {
      opacity: 1;
      transition: opacity 0.2s 0.7s;
    }

    .cta__content span:last-of-type {
      scale: 1;
      opacity: 1;
      transition: scale 0.2s 0.4s, opacity 0.2s 0.4s;
    }
  }
}