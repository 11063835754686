@import '../../../../styles/colors.scss';

/*  ================= Banner Section ================= */
.heroSliderMain {
  .banner-sec {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    /* border-radius: 0 0 30px 30px; */
    // overflow: hidden;
    background-color: $dark-1;
    margin: auto;
    max-width: 1720px !important;

    &.sm {
      min-height: 1024px;
      align-items: center;
    }

    &.lg {
      min-height: 920px;
      padding-top: 340px;
    }

    .banner-text {


      .heading {
        background-color: $grey-4;
        background-image: linear-gradient(90deg, $grey-4, $dark-3);
        background-size: 100%;
        background-repeat: repeat;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        max-width: 93.6rem;
        font-weight: 500;
      }

      .all-text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.6rem;
        margin: 0;
        padding: 0;
        max-width: 100%;
        width: 100%;
      }

      .subtitle {
        color: $white;
        margin-bottom: 40px;

        .subtitle-style {
          max-width: 93.6rem;
          color: $accent-4;
        }

        span {
          color: $primary-brand-3;
          font-size: inherit;
        }
      }
    }

    /*  ================= Banner Media  ================= */
    img {
      position: absolute;
      object-fit: contain;

      &.desktop {
        width: 350px;
        height: 234px;
        border-radius: 30px;

        /* Hide on mobile */
        display: none;
      }

      &.mobile {
        width: 162px;
        height: 122px;
        border-radius: 8px;

        /* Show on mobile */
        display: block;
      }
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1200px) {
  .banner-text {

    .all-text-container {
      max-width: 75%;
      width: 100%;
    }
  }
}

@media (min-width: 992px) {
  .heroSliderMain {
    .banner-sec {
      &.lg {
        min-height: 1366px;
        padding-top: 340px;
      }

      .banner-text {
        .all-text-container {
          max-width: 85%;
          width: 100%;
        }
      }

      img {
        &.mobile {
          display: none;
        }

        &.desktop {
          display: block;
        }
      }
    }
  }
}