/* ================= General ================= */
body {
  background: #041e2c;
  font-family: 'Heebo', sans-serif;
  color: white;
}

section {
  position: relative;
  display: block;
}

/*  =================Subscribe section  =================*/
.subscribe-sec {
  min-height: 910px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 30px 30px;
  background: radial-gradient(122.71% 245.32% at 50% -145.32%, #9ACBFF 0%, #041E2D 85.46%, #041E2D 100%);
}

.subscribe-sec .row {
  justify-content: center;
  text-align: center;
}

h2 {
  background-color: #f3f4f5;
  background-image: linear-gradient(45deg, #f3f4f5, #7a8890);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Heebo;
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  /* 114.286% */
  margin-bottom: 40px;
}

/* Subscribe Form */
form {
  position: relative;
  display: block;
}

form input {
  width: 100%;
  border-radius: 48px;
  border: 0.1px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.10);
  color: white;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  height: 64px;
  padding-left: 20px;
}

form input::placeholder {
  color: #BDCEE0;
}