@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

.careers-container {
  width: 100%;
  height: 100%;

  .subtitle-styling {
    max-width: 55.2rem;
  }

  .subtitle-style-1 {
    max-width: 59.5rem;
  }

  .main-heading {
    max-width: 93.6rem;
    text-align: left;
  }
}

.title-center {
  // width: 55.2rem;
  text-align: center;
  color: $white;
  word-wrap: break-word;
}

.title-left {
  // width: 55.2rem;
  text-align: left;
  color: $white;
  word-wrap: break-word;
}

.subtitle-center {
  // width: 55.2rem;
  text-align: center;
  color: $primary-brand-3;
  word-wrap: break-word;
}

.subtitle-left {
  // width: 55.2rem;
  text-align: left;
  color: $primary-brand-3;
  word-wrap: break-word;
}

// blue section 1 styling
// *********************************************************

.blue-section-01-styles {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 1000px;
  box-shadow: -32px 32px 48px rgba($dark-3, 0.32);
  justify-content: center;

  @media only screen and (max-width:$sm) {
    padding: 0 2rem;
  }

  .vor-button {
    height: 5.6rem;
  }

  .jot-heading {
    max-width: 55.2rem;
  }
}

.text-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3.2rem;
}

.text-wrapper {
  gap: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-wrapper {
  padding: 1.6rem 3.2rem;
  border-radius: 5rem;
  border: 0.2rem $border-gradient-end solid;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
  align-items: center;
  color: $white;

  &:hover {
    background-color: $primary-brand-1;
  }
}

// image 1 styling
// *********************************************************
.image-container {
  width: 100%;
  // height: 960px;
  position: relative;
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 960px !important;
  object-fit: cover;

  @media only screen and (max-width:$sm) {
    height: 284px !important;
  }
}

.ci-2 {
  height: 810px !important;

  @media only screen and (max-width:$sm) {
    height: 320px !important;
  }
}

// blue section 2 styling
// *********************************************************

.blue-section-02-styles {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  height: min-content;
  box-shadow: 0px -32px 48px rgba($dark-3, 0.32);
}

@media only screen and (max-width:$sm) {
  .accordions {
    margin: 0;
    padding: 0;
  }
}

.text-button-container-02 {
  margin-top: 16rem;
  margin-bottom: 8rem;




  @media only screen and (max-width:$sm) {
    margin-top: 10rem;
  }
}

.text-wrapper-02 {
  display: flex;
  //   align-items: center;
  // justify-content: center;
  flex-direction: column;
  gap: 1.6rem;
}

.image-container-02 {
  width: 100%;

  position: relative;
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
  overflow: hidden;
  border-radius: 3rem;

  .ic-2 {
    max-width: 396px !important;
  }
}

// blue section 3 styling
// *********************************************************
.blue-section-03-styles {
  height: 350px;
  background: radial-gradient(122.71% 245.32% at 50% 245.32%,
      $secondary-brand-3 0%,
      $dark-1 85.46%,
      $dark-1 100%);
}

// accordion styling
// *********************************************************
.mainHeading {
  text-align: left;
  margin-bottom: 5rem;
}

.accordions {
  margin-top: 8rem;
  gap: 50px !important;
}



.accordion {
  display: flex;
  padding: 9.6rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 3rem;
  border: 0.1rem solid rgba($border-gradient-end, 0.1);
  background: $accent-1;
}

.all-accordions {
  gap: 3.2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}


@media only screen and (max-width: $sm) {
  .accordion {
    padding: 4rem 1.6rem;
  }

}