@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';


.creator-onboarding-after-signup-container {
    display: grid;
    grid-template-rows: 8rem 1fr;

    .creator-onboarding-navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        z-index: 999;
        position: sticky;
        top: 0;

        .navbar-brand {
            .text-logo {
                position: relative;
                z-index: 999;
                visibility: visible;
                opacity: 1;
                margin-left: 1rem;
                max-width: 100px;

                @media only screen and (max-width:$md) {
                    visibility: hidden;
                    transition: visibility 0.2s, opacity 0.2s linear, transform 0.2s ease-in-out;
                    transform: translateX(-10px);
                    opacity: 0;
                }
            }

            .logo {
                margin-left: 2rem;

                &:hover+.text-logo {
                    visibility: visible;
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        .avatar-button {
            display: flex;
            flex-direction: column;
            margin-right: 2rem;
            position: relative;

            .avatar {
                border: none;
                outline: none;
            }
        }

        .button-triangle {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            right: 2rem;
            margin-top: 1.5rem;

            .logout-tooltip {
                position: absolute;
                background-color: $border-gradient-start;
                color: $white;
                top: 10%;
                padding: 0.8rem;
                border-radius: 5rem;
                position: relative;
                border: none;
                outline: none;
                width: 14.8rem;

            }

            .triangle-container {
                position: absolute;
                width: 2rem;
                height: 2rem;
                border-left: 17px solid transparent;
                border-right: 17px solid transparent;
                border-bottom: 20px solid $border-gradient-start;
                top: -25%;
                right: 1.5rem;
            }

            .log-out-text {
                width: 100%;
            }
        }
    }

    .creator-onboarding-body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        height: 100%;

        @media only screen and (max-width:$md) {
            grid-template-columns: 100%;
            row-gap: 5rem;
        }

    }
}