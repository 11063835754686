@import '../../../../styles/colors.scss';

/* utility classes */
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* search bar styles */
.search-bar {
  height: 48px;
  padding-left: 35px;
  background: $accent-1;
  border-radius: 16px;
  overflow: hidden;
  border: 0.1px rgba($white, 0.1) solid;
  justify-content: flex-end;
  align-items: center;
  gap: 383px;
  display: inline-flex;
  width: 100%;

  .input-field,
  .search-button {
    text-align: center;
    word-wrap: break-word;
  }

  .input-field {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: $border-gradient-end;
    width: 100%;

    input::placeholder {
      color: $dark-4;
    }

    .search-icon {
      width: 1.6rem;
      height: 1.6rem;
      color: $white;
    }

    .search-input {
      width: 100%;
      background-color: transparent;
      outline: none;
      border: none;
      color: $dark-4;
    }
  }

  .search-button-container {
    width: 154px;
    align-self: stretch;
    padding: 16px 32px;
    background: $secondary-brand-1;
    border-radius: 48px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;

    .search-button {
      color: $white;

    }
  }
}

/* media queries */
@media only screen and (max-width: 800px) {
  .search-bar {
    gap: 300px;
  }
}

@media only screen and (max-width: 700px) {
  .search-bar {
    gap: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .search-bar {
    gap: 100px;
  }
}

@media only screen and (max-width: 500px) {
  .search-bar {
    gap: 50px;
  }


}

@media only screen and (max-width: 400px) {
  .search-bar {
    gap: 20px;
  }
}