@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

.create-new-password-container {
    display: flex;

    .create-new-password-left-container {
        background: linear-gradient(-45deg, rgba(183, 10, 128, 0.67), rgba(33, 64, 154, 0.71), rgba(4, 30, 45, 1), );
        width: 48rem;
        height: 100vh;
    }

    .create-new-password-right-container {
        display: grid;
        grid-template-rows: min-content;
        justify-content: center;
        flex-direction: column;
        width: 100%;


        .cnp-right-container-align-center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 55.2rem;
            height: 100vh;

            .logo {
                height: 4rem;
                width: 4rem;
                margin: 2rem auto;
                opacity: 0.7;
            }

            .link-color {
                color: $white;
            }

            .choose-password-text {
                margin: 0 0 1rem 0;
            }

            .choose-password-sub-text {
                margin-bottom: 2rem;
                color: $dark-3;
                text-align: center;
            }

            .choose-password-body-container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                gap: 1rem;

                .helper-text {
                    display: flex;
                    gap: 2rem;
                    align-items: center;

                    .helper-success {
                        display: none;
                        font-family: Poppins;

                        &.hp-success {
                            display: block;
                        }

                        .check-icon {
                            margin-right: 0.5rem;
                            background-color: $success-2;
                            border-radius: 100%;
                            stroke: $dark-1;
                        }
                    }

                    .helper-text-1,
                    .helper-text-2 {
                        display: flex;
                        align-items: center;
                        gap: 1rem;

                        &.hidden {
                            display: none;
                        }

                        .point-circle {
                            width: 0.6rem;
                            height: 0.6rem;
                            border-radius: 0.3rem;
                            background-color: $primary-brand-1;

                        }

                        .hp-1-text {
                            &.hp-checked {
                                text-decoration: line-through;
                            }
                        }


                    }

                }

                .choose-password-field-name {
                    margin-bottom: 0.5rem;
                    margin-top: 1rem;
                }


                .choose-password-next-button {
                    color: $white;
                    width: 100%;
                    // height: 100%;
                    border-radius: 20rem;
                    margin-top: 5rem;
                    position: relative;
                }
            }
        }

        @media only screen and (max-width: $xs) {
            .cnp-right-container-align-center {
                width: unset;
                padding: 0 1rem;
            }
        }
    }
}

@media only screen and (max-width: $lg) {
    .create-new-password-left-container {
        display: none;
    }
}

@media only screen and (max-width: $md) {
    .create-new-password-container {
        flex-direction: column;
    }
}