@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';


.whats-new-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 160px;

  .section-02-containers {
    margin: 0;
    padding: 0;
  }

  .spanStyle {
    color: $primary-brand-3;
  }

  .eclipse {
    position: absolute;
    width: 496px;
    height: 496px;
    flex-shrink: 0;
    border-radius: 496px;
    opacity: 0.24;
    background: $border-gradient-end;
    filter: blur(180.5px);
    transform: translate(-34%, -50%);
  }

  @media only screen and (max-width: 800px) {
    .eclipse {
      width: 234px;
      height: 234px;
      flex-shrink: 0;
      fill: $border-gradient-end;
      opacity: 0.32;
      filter: blur(99.5px);
    }
  }

  .blue-section-01-styles {
    height: 812px;
    border-radius: 0px 0px 30px 30px;
    background: radial-gradient(142.11% 284.06% at 51.6% -184.05%,
        $secondary-brand-3 0%,
        $dark-1 85.46%,
        $dark-1 100%);
    box-shadow: 30px 60px 77px 0px rgba($accent-2, 0.77);
    flex-shrink: 0;
    z-index: 1;
  }

  @media only screen and (max-width: 600px) {
    .blue-section-01-styles {
      height: 448px;
      border-radius: 0px 0px 16px 16px;
      background: radial-gradient(122.71% 245.32% at 50% -145.32%,
          $secondary-brand-3 0%,
          $dark-1 85.46%,
          $dark-1 100%);
    }
  }

  .blue-section-01-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .big-heading {
      text-align: center;

      background: linear-gradient(92deg,
          $white 9.82%,
          rgba($white, 0) 160.12%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: min-content;
      margin-bottom: 16px;
    }

    .sub-headingg {
      text-align: center;
      color: $primary-brand-3;

    }

    .sub-heading {
      text-align: center;
      color: $white;

      span {
        color: $primary-brand-3;
      }
    }

    .pstyles {
      color: $white;
      text-align: center;
    }


  }

  .section-02-containers {
    display: flex;
    flex-direction: column;
    gap: 120px;
  }

  .half-image-text {
    margin: 0;
    padding: 0;
  }

  .imageDescriptions {
    .id {
      padding: 0;
      margin: 0;
    }
  }

  .section-03-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 80px;

    @media only screen and (max-width:$md) {
      margin-bottom: 10px;
    }

    .big-heading {

      color: $white;

      margin-bottom: 9px;
    }

    .sub-heading {

      font-family: Heebo;
    }

    .pstyles {
      color: $white;
      max-width: 55.2rem;
    }


  }

  .plain-section-blue-section {
    .eclipse1 {
      position: absolute;
      width: 496px;
      height: 649px;
      flex-shrink: 0;
      border-radius: 496px;
      opacity: 0.24;
      background: $border-gradient-end;
      filter: blur(180.5px);
      transform: translateX(-50%);
      left: 50%;
      z-index: -1;
      margin-top: 80px;
    }

    .plain-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 32px;
      border-radius: 30px;
      border: 1px solid rgba($border-gradient-end, 10%);
      padding: 96px 117px;
      background: $accent-1;

      height: 538px;
      position: relative;
      z-index: 1;

      .text {
        color: $white;
        text-align: center;
        font-family: Heebo;

      }

      @media only screen and (max-width: 770px) {
        padding: 96px 40px;
      }

      @media only screen and (max-width: 500px) {
        padding: 96px 20px;
      }
    }

    @media only screen and (max-width: 995px) {
      .search-bar {
        gap: 300px;
      }
    }

    @media only screen and (max-width: 770px) {
      .search-bar {
        gap: 150px;
      }
    }

    @media only screen and (max-width: 605px) {
      .search-bar {
        gap: 10px;
        overflow: visible;
        // margin: 0 20px;
      }
    }

    .blue-section-styles {
      height: 662px;
      width: 100%;
      z-index: -10;
      background: radial-gradient(122.71% 245.32% at 50% 245.32%,
          $secondary-brand-3 0%,
          $dark-1 85.46%,
          $dark-1 100%);

      // border: 1px solid yellow;
    }
  }

  .common-questions-container {
    margin: 0;

    .main-headingg {
      color: $white;
      text-align: center;
      font-family: Heebo;
    }
  }

  @media only screen and (max-width: 800px) {
    .half-image-text {
      .text {
        margin-top: 24px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .whats-new-container {
    gap: 80px;

    .blue-section-middle {
      height: min-content;
    }

    .eclipse1 {
      display: none;
    }

    .plain-section-blue-section .plain-section {
      height: 212px;

      .text {
        color: $white;
        text-align: center;

      }
    }

    .common-questions-container {
      margin: 0;

      .main-headingg {

        color: $white;
        text-align: left;

      }
    }

    .blue-section-01-styles {
      border-radius: 0px 0px 16px 16px;
      background: radial-gradient(122.71% 245.32% at 50% -145.32%,
          $secondary-brand-3 0%,
          $dark-1 85.46%,
          $dark-1 100%);
    }

    .plain-section-blue-section .blue-section-styles {
      height: 380px;
    }

    .section-text-style {
      padding: 0 20px;
    }
  }
}

//
button.subscribe-btn {
  color: #FFF;
  font-family: Heebo;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 48px;
  background: #21409A;
  padding: 15px 32px;
  border: 1px solid #21409a;
  transition: 0.3s;
  outline: none;
  height: 64px;
  position: absolute;
  right: 0px;
}

button.subscribe-btn:hover {
  background-color: #b70a80;
}

.row {
  justify-content: center;
}

section.parent {
  view-timeline-name: --section;
  width: 74.4rem;
  --content-size: 100%;

  form input {
    height: 65px;
  }

  @media only screen and (max-width: $lg) {
    width: 55.4rem;
  }

  @media only screen and (max-width: $md) {
    width: 45.4rem
  }

  @media only screen and (max-width: $sm) {
    width: 34.4rem;
  }

  /* CTA Specific stuff goes here.... */
  .cta {
    /* Activates showing the CTA */
    animation: activate both linear, activate linear reverse;
    animation-timeline: --section, view();
    animation-range: entry, cover 50%;
  }

  /* Word */
  .cta span:nth-of-type(1) {
    opacity: var(--scale, 0);
    transition: opacity 0.2s 0.6s;
    white-space: nowrap;
  }

  /* The icon holder */
  .cta span:nth-of-type(2) {
    display: grid;
    place-items: center;
  }

  .cta .input-container {
    --elastic: linear(0, 0.4789 7.06%, 0.7612 12.38%,
        0.8693 15.13%, 0.9538 17.95%,
        1.0165 20.89%, 1.059 24.04%,
        1.0829 27.58%, 1.0884 31.64%,
        1.0769 36.44%, 1.0202 50.6%,
        1.0005 59.62%, 0.9945 70.6%, 1);
    display: grid;
    grid-template-columns: 0 36px;
  }

  @keyframes activate {
    0% {
      --active: 0;
    }

    100% {
      --active: 1;
    }
  }
}

/* Use style queries to dictate the timing, etc. */
@container style(--active: 0) {
  section.parent {
    .cta__content::before {
      scale: 0;
      opacity: 0;
      transition: scale 0.5s var(--back);
    }

    .cta .input-container {
      scale: 0;
      transition: scale 0.2s 0.7s, grid-template-columns 0.5s 0.2s var(--elastic);
    }

    .cta__content span:first-of-type {
      opacity: 0;
      transition: opacity 0.2s 0s;
    }

    .cta__content span:last-of-type {
      scale: 0;
      opacity: 0;
      transition: scale 0.2s, opacity 0.2s;
    }
  }
}

@container style(--active: 1) {
  section.parent {
    .cta__content::before {
      scale: 0.99;
      opacity: 1;
      transition: scale 0.5s var(--back);
    }

    .cta .input-container {
      scale: 1;
      transition: scale 0.2s 0s, grid-template-columns 0.5s 0.6s var(--elastic);
      grid-template-columns: var(--content-size, auto) 0px;
    }

    .cta__content span:first-of-type {
      opacity: 1;
      transition: opacity 0.2s 0.7s;
    }

    .cta__content span:last-of-type {
      scale: 1;
      opacity: 1;
      transition: scale 0.2s 0.4s, opacity 0.2s 0.4s;
    }
  }
}