@import '../../../../styles/breakpoints.scss';
@import '../../../../styles/colors.scss';

.header-1 {
  font-weight: 700;
  font-size: 8.8rem;
  letter-spacing: 0;
  line-height: 8.8rem;
}

.header-2 {
  font-weight: 500;
  font-size: 4.8rem;
  letter-spacing: 0;
  line-height: 5.6rem;
}

.header-3 {
  font-weight: 700;
  font-size: 3.6rem;
  letter-spacing: 0;
  line-height: 4.4rem;
}

.subtitle {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.body-1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-2 {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.small-text {
  font-weight: 500;
  font-size: 1.4rem;
}

.pre-title {
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 2.4rem;
}

.button-text {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
}

.link-text {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $white;
}

@media only screen and (max-width:$lg) {
  .header-1 {
    font-weight: 600;
    font-size: 6.8rem;
    letter-spacing: 0%;
    line-height: 6.8rem;
  }

  .header-2 {
    font-weight: 600;
    font-size: 4rem;
    letter-spacing: 0%;
    line-height: 4.8rem;
  }

  .header-3 {
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: 0%;
    line-height: 4.2rem;
  }

  .subtitle {
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 2.9rem;
  }

  .body-1 {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  //same
  .body-2 {
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 2.4rem;
  }

  

  .pre-title {
    font-size: 1.8rem;
    letter-spacing: 0;
    line-height: 2.4rem;
  }

  // not used
  .button-text {
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: 0%;
    line-height: 2.4rem;
  }

  //same
  .link-text {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media only screen and (max-width:$sm) {
  .header-1 {
    font-weight: 500;
    font-size: 4.8rem;
    letter-spacing: 0%;
    line-height: 5.6rem !important;
  }

  .header-2 {
    font-weight: 500;
    font-size: 3.2rem;
    letter-spacing: 0%;
    line-height: 4rem !important;
  }

  .header-3 {
    font-weight: 500;
    font-size: 2.4rem;
    letter-spacing: 0%;
    line-height: 3rem !important;
  }

  .subtitle {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem !important;
  }

  .body-1 {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem !important;
  }

  //same
  .body-2 {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.4rem !important;
  }

  

  .pre-title {
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 2.4rem !important;
  }

  // not used
  .button-text {
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: 0%;
    line-height: 2.4rem !important;
  }

  //same
  .link-text {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem !important;
  }
}