@import '../../../../styles/colors.scss';
@import '../../../../styles/breakpoints.scss';


.img-container {
  .cover {
    width: 100%;
    max-width: 55.2rem;
    max-height: 36.8rem;
    object-fit: cover;
    margin-bottom: 40px;

    @media only screen and (max-width:$md) {
      margin-bottom: 32px;
      padding: 0 1.6rem;
    }
  }
}

.text-container {
  margin-left: 25px;

  margin-bottom: 120px;

  @media only screen and (max-width:$md) {
    margin-left: 1.6rem;
  }

  .logo {
    height: 30px;
    margin-bottom: 18px;

    img {
      color: $dark-3;
    }
  }

  .subtitle {
    color: $grey-4;
  }

  .linkStyle {
    margin-top: 2rem;
  }

  a {
    color: $grey-4;
    margin-top: 5rem;
    text-decoration: none;
  }
}