@import '../../../../styles/colors.scss';

.options-selector {


    .form-select {
        background-color: $dark-1;
        color: $white;
        height: 5.6rem;
        border: 1px solid $border-gradient-start;
        border-radius: 1.2rem;
        outline: none;
        font-size: 1.4rem;
        box-shadow: none;
        transition: none;
        --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23818e96' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e);

        .placeholder-text {
            color: $dark-3;
        }

        option {
            color: $white;
        }

        &:focus {
            outline: none;
            border-color: $white;
            box-shadow: none;
        }

    }

    .field-style {
        &.options-selector-error {
            border: 1px solid $error;

        }

        &.selected {
            color: $white;
        }

        &.not-selected {
            color: $dark-3;
        }
    }

    .helper-text {
        color: $error;
    }
}