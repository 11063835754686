@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';


.form-container {
    padding: 0;
    margin: 0;
}

.sign-up-container {
    display: flex;
    align-items: center;

    .link-style {
        color: $white;
    }

    .left-containerr {
        background: linear-gradient(-45deg, rgba(183, 10, 128, 0.67), rgba(33, 64, 154, 0.71), rgba(4, 30, 45, 1), );

        width: 48rem;
        height: 100vh;
    }

    .right-container {
        display: grid;
        grid-template-rows: min-content;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0;

        .align-center-code {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100vh;
            width: 55.2rem;

            .logo {
                height: 4rem;
                width: 4rem;
                margin: 0 auto;
                opacity: 0.7;
            }

            .code-text {
                margin: 0 0 1rem 0;
            }

            .sub-text {
                margin-bottom: 2rem;
                color: $dark-3;
                text-align: center;
            }

            .body-container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                gap: 1rem;

                .field-name {
                    margin-bottom: 0.5rem;
                    margin-top: 1rem;
                }

                .input-style {
                    margin-bottom: 0.5rem;
                }

                .link-subtext {
                    margin-bottom: 5rem;
                    margin-top: 1rem;
                }

                .linkStylee {
                    color: $dark-4;
                    text-decoration: none;

                }
            }



            .next-button-link {
                max-width: 55.2rem;
                width: 100%;
                margin-right: auto;

            }

            .next-button {
                color: $white;
                width: 100%;
                position: relative;
            }

        }


        @media only screen and (max-width: $xs) {



            .align-center-code {
                padding: 0 1rem;
                width: unset;

                .next-button-link {
                    max-width: unset;
                }
            }

        }
    }
}

@media only screen and (max-width: $lg) {
    .left-containerr {
        display: none;
    }
}

@media only screen and (max-width: $md) {
    .sign-up-container {
        flex-direction: column;
    }


}