@import '../../../../styles/colors.scss';


.icon-text-card-container {
  width: 360px;
  height: 232px;
  border-radius: 27px;
  border: 1px solid rgba($white, 0.05);
  background: $dark-2;
  position: relative;

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    position: absolute;
    left: 20px;
    top: 32px;

    .icon-style {
      width: 32px;
      height: 32px;
      color: rgba($primary-brand-1, 1);
      
    }

    .heading-style {
      color: $white;
      text-align: center;
      margin-top: 20px;
    }

    .sub-heading-style {
      color: $accent-3;

    }
  }
}
