@import '../../../../styles/colors.scss';

.bg-image-text-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  width: 360px;
  height: 360px;
  overflow: hidden;
  position: relative;
  border-radius: 30px;
  cursor: pointer;

  &:hover {
    .image-style {
      transform: scale(1) !important; // Zoom in on hover
    }
  }

  &:hover {
    .image-headingg {
      .stc-button {
        background-color: $white;

        .stc-icon {
          transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
          color: $dark-1;


        }
      }
    }
  }

  .icon-styling {
    z-index: 10;
    position: absolute;
    bottom: 2rem;
  }

  .stc-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($dark-1, .5);
    border: 2px solid rgba($grey-4, 0.2);
    outline: rgba($grey-4, .1);
    width: 3rem;
    margin-right: 2rem;
    height: 3rem;
    border-radius: 100%;
    z-index: 2;
    transition: all 0.3s ease;


    .stc-icon {
      transition: 0.2s fill cubic-bezier(0, 0.44, 0.6, 1), 0.2s transform cubic-bezier(0, 0.44, 0.6, 1);
      color: $white;
      width: 100%;
      height: 100%;

      &.cross {
        transform: rotate(45deg);
      }
    }

    &:hover {
      background-color: $white;

      .stc-icon {
        transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
        color: $dark-1;

      }
    }
  }

  .image-style {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 7;
    border-radius: 30px;
    object-fit: cover;
    transform: scale(0.99); // Start with a smaller scale
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1) !important; // Zoom in on hover
    }
  }

  .image-headingg {
    color: $white;
    padding-bottom: 24px;
    width: 100%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    z-index: 100;


    .heading-icon {
      display: flex;
      flex-direction: column;
    }

    .stc-heading {
      margin-left: 2rem;
    }


    &.show {
      background: linear-gradient(to top, rgba($dark-1, .2), transparent), // Apply gradient to image
        linear-gradient(7.96deg, #000 14.71%, transparent 78.17%); // Text gradient
      // transform: translateY(-100%);
      animation: reveal 0.4s ease, gradient-reveal 0.4s ease;
      opacity: 1;
    }
  }

  .stc-description {
    position: relative;
    color: $secondary-brand-4;
    margin-left: 2rem;
    max-width: 100%;
    margin-top: 0.8rem;
    overflow: hidden;
    z-index: 10;
    display: grid;
    grid-template-columns: 1fr 5rem;
    justify-content: flex-end;
    align-items: flex-end;
  }
}


@keyframes gradient-reveal {
  0% {
    opacity: 0;
    /* Start with gradient hidden */
  }

  50% {
    opacity: 0;
    /* Keep gradient hidden halfway */
  }

  100% {
    opacity: 1;
    /* Show gradient fully when text appears */
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes unreveal {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes expand {
  0% {
    height: 360px;
  }

  100% {
    height: 100%;
  }
}

@keyframes unexpand {
  0% {
    height: 360px;
  }

  100% {
    height: 360px;
  }
}