@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

.choose-method-container {
    display: flex;

    .cmc-left-container {
        background: linear-gradient(-45deg, rgba(183, 10, 128, 0.67), rgba(33, 64, 154, 0.71), rgba(4, 30, 45, 1), );
        width: 48rem;
        height: 100vh;
    }

    .cmc-right-container {
        display: grid;
        grid-template-rows: 5rem min-content;
        align-items: center;
        // justify-content: center;
        flex-direction: column;
        width: 100%;



        .back-button {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            margin-left: 2rem;
            cursor: pointer;
            width: min-content;
            color: $white;
        }

        .cmc-align-center {
            display: flex;
            flex-direction: column;
            // align-items: center;
            justify-content: center;
            width: 55.2rem;
            height: 100vh;
            margin: 0 auto;

            .logo {
                height: 4rem;
                width: 4rem;
                margin: 2rem auto;
                opacity: 0.7;
            }

            .cmc-text {
                margin: 1rem 0 1rem 0;
                text-align: center;
            }

            .cmc-sub-text {
                margin-bottom: 2rem;
                color: $dark-3;
                text-align: center;
            }

            .radio-text-1,
            .radio-text-2 {
                display: grid;
                grid-template-columns: repeat(2, max-content);
                gap: 2rem;
                align-items: flex-start;
                margin-bottom: 2rem;
            }

            .radio-text-2 {
                margin-bottom: 3rem;
            }


            .cmc-next-button {
                width: 100%;
                color: $white;
                position: relative;
            }
        }

        @media only screen and (max-width: $xs) {
            .cmc-align-center {
                width: unset;
                padding: 0 1rem;
            }
        }
    }

    @media only screen and (max-width: $lg) {
        .cmc-left-container {
            display: none;
        }
    }
}