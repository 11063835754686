@media only screen and (max-width: 767px) {
  .subscribe-sec {
    min-height: 410px;
  }

  .copyright {
    display: block;
  }

  .copyright p {
    margin: 10px 0px 0px 0px;
  }

  ul.social-icon li a {
    margin: 0 10px !important;
  }

  footer * {
    text-align: left !important;
  }

  .copyright p,
  .copyright a {
    display: inline-block;
  }

  .copyright p {
    margin: 0px 0px 20px 14px;
  }

  .copyright ul {
    display: block;
    margin-bottom: 20px;
  }

  .copyright ul li {
    display: block;
    margin-left: 0;
  }

  ul.social-icon li:first-child a {
    margin-left: 0 !important;
  }

  footer .row {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 640px) {
  h2 {
    font-size: 32px;
    line-height: 40px;
  }

  form button {
    font-size: 16px;
    padding: 15px 22px;
    height: 50px;
    height: auto;
  }

  form input {
    height: 55px;
    padding-right: 126px;
  }

  .subscribe-sec {
    min-height: 335px;
  }
}
