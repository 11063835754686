.animate__fadeInUp {
    animation-duration: 1s
}

.animate__fadeInUpBig {
    animation-duration: 1s
}

.animate__fadeInLeft {
    animation-duration: 1s
}

.animate__fadeInRight {
    animation-duration: 1s
}