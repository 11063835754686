@import '../../../../styles/colors.scss';
@import '../../../../styles/breakpoints.scss';

.slick-slider {
    * {
        border: none;
        outline: none;
    }

    max-width: 55.2rem;
    outline: none;
    border: none;
    border-radius: 30px;
    background-color: transparent;
    position: relative;

    .slick-slide {
        position: relative;
        margin: 0 auto;
        padding: 0 0.5rem;
        overflow: hidden;
        outline: none;
        border: none;

        img {
            margin: 0;
            overflow: hidden;
            outline: none;
            border: none;
            border-radius: 30px;

        }

        .image-text-style {
            position: absolute;
            bottom: 2rem;
            left: 0;
            text-align: center;
            color: $white;
            margin-left: 3.2rem;
            padding: 5px;
            z-index: 1;

        }


        .img-fluid {
            box-shadow: none;
            outline: none;
            border: none;


        }
    }
}

.slick-slider * {
    // display: flex !important;
    // align-items: flex-end;
    // justify-content: flex-end;

}

.custom-dot {
    width: 0.5rem;
    height: 0.5rem;
    background-color: $white;
    opacity: 50%;
    border-radius: 50%;
    display: inline-block;
    margin: 2rem 0 0 0;
    padding: 0;

}

.slick-active .custom-dot {
    width: 0.8rem;
    height: 0.8rem;
    opacity: 100%;
    background-color: $white;
}


.slick-dots li {
    width: 10px;
}

@import '../../../../styles/colors.scss';

.bg-image-text-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    width: 360px;
    height: 360px;
    overflow: hidden;
    position: relative;
    border-radius: 30px;
    cursor: pointer;

    &:hover {
        .image-style {
            transform: scale(1) !important; // Zoom in on hover
        }
    }

    &:hover {
        .image-heading {
            .stc-button {
                background-color: $white;

                .stc-icon {
                    transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
                    color: $dark-1;


                }
            }
        }
    }

    .icon-styling {
        z-index: 10;
        position: absolute;
        bottom: 4.5rem !important;
        margin-left: 2rem;
        right: 0.1rem;
    }

    .stc-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($dark-1, .5);
        border: 2px solid rgba($grey-4, 0.2);
        outline: rgba($grey-4, .1);
        width: 3rem;
        margin-right: 2rem;
        height: 3rem;
        border-radius: 100%;
        z-index: 2;
        transition: all 0.3s ease;


        .stc-icon {
            transition: 0.2s fill cubic-bezier(0, 0.44, 0.6, 1), 0.2s transform cubic-bezier(0, 0.44, 0.6, 1);
            color: $white;
            width: 100%;
            height: 100%;

            &.cross {
                transform: rotate(45deg);
            }
        }

        &:hover {
            background-color: $white;

            .stc-icon {
                transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
                color: $dark-1;

            }
        }
    }

    .image-style {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 7;
        border-radius: 30px;
        object-fit: cover;
        transform: scale(0.99); // Start with a smaller scale
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1) !important; // Zoom in on hover
        }
    }

    .image-heading {
        color: $white;
        padding-bottom: 42px;
        width: 100%;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        z-index: 10;
        position: absolute;
        bottom: 0rem;
        // margin: 0 2rem;


        .heading-icon {
            display: flex;
            flex-direction: column;

            &.right {
                position: absolute;
                bottom: 2rem;
                right: 7rem;

            }
        }

        .stc-heading {
            margin-left: 2rem;
            position: relative;




        }


        &.show {
            background: linear-gradient(to top, rgba($dark-1, .2), transparent), // Apply gradient to image
                linear-gradient(7.96deg, #000 14.71%, transparent 78.17%); // Text gradient
            // transform: translateY(-100%);
            animation: reveal 0.4s ease, gradient-reveal 0.4s ease;
            opacity: 1;
        }
    }

    .stc-description {
        position: relative;
        color: $secondary-brand-4;
        margin-left: 2rem;
        max-width: 100%;

        overflow: hidden;
        z-index: 10;
        display: grid;
        grid-template-columns: 1fr 5rem;
        justify-content: flex-end;
        align-items: flex-end;
        width: 95%;
    }
}


@keyframes gradient-reveal {
    0% {
        opacity: 0;
        /* Start with gradient hidden */
    }

    50% {
        opacity: 0;
        /* Keep gradient hidden halfway */
    }

    100% {
        opacity: 1;
        /* Show gradient fully when text appears */
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes reveal {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes unreveal {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(100%);
    }
}

@keyframes expand {
    0% {
        height: 360px;
    }

    100% {
        height: 100%;
    }
}

@keyframes unexpand {
    0% {
        height: 360px;
    }

    100% {
        height: 360px;
    }
}