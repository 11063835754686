@import '../../../../styles/colors.scss';
@import '../../../../styles/breakpoints.scss';



// Blue section styles
.blue-section {
  display: flex;
  flex-direction: column;
  z-index: 999;
  position: relative;
  overflow: hidden;
  background-color: $dark-1;

  .section-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center !important;
    background: linear-gradient(92.3deg, #FFFFFF 9.82%, rgba(255, 255, 255, 0) 160.12%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    flex-shrink: 0;

    @media only screen and (max-width:$md) {
      padding: 0 1.6rem
    }
  }

  .blue-section-text-center {
    max-width: 93.6rem;
  }


  span {
    color: $primary-brand-4;
  }

  // media query
  @media (max-width: 768px) {
    height: 500px;
  }
}