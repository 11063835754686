@import '../../../../styles/colors.scss';

.primary {
    background-color: $primary-brand-1;
    color: $white;
    border-radius: 28px;
    padding: 14px 24px;
    font-size: 14px;

    &:hover {
        background-color: $primary-brand-2;
    }
}

.btn:disabled {
    background-color: rgba($white, 0.15);
    border: none;
    outline: none;
    cursor: not-allowed;
}


// .primary-outlined {}

// .secondary {}

.secondary-outlined {
    background-color: transparent;
    color: $white;
    border: 2px solid $border-gradient-start;
    border-radius: 28px;
    padding: 14px 24px;
    font-size: 14px;

    &:hover {
        background-color: rgba($white, 0.12);
    }

    &:active {
        background-color: $primary-brand-1 !important;
    }
}

.link {
    background-color: transparent;
    color: $white;
    border: 2px solid transparent;
    border-radius: 28px;
    padding: 14px 24px;
    font-size: 14px;

    &:hover {
        background-color: $primary-brand-1;
    }

    &:active {
        background-color: $primary-brand-2 !important;
    }
}

.link-outlined {
    background-color: transparent;
    color: $white;
    border: 1px solid $border-gradient-start;
    border-radius: 28px;
    padding: 14px 24px;
    font-size: 14px;

    &:hover {
        background-color: rgba($white, 0.12);
    }

    &:active {
        background-color: $primary-brand-2 !important;
    }
}