@import '../../../../styles/colors.scss';

.percentage-text {
  .topText {
    color: $primary-brand-3;
  }
  .bottomText {
    max-width: 27.2rem;
    color: $primary-brand-3 !important;
    font-weight: 400;
  }
  .percentage-symbol {
    background-image: linear-gradient(
      180deg,
      rgba($white, 0.69) 0%,
      rgba($white, 0) 100%
    );
    background-size: 100%;
    background-repeat: repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background-clip: text;
    font-family: Heebo;
  }
}
