.slick-slider {
    max-width: 100%;
    margin-top: 10rem;
}

@media only screen and (max-width:500px) {
    .slick-slide {
        .img-fluid {
            padding: 3rem 0;
            height: min-content;
        }
    }
}