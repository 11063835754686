@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

.find-account-container {
    display: flex;

    .find-account-left-container {
        background: linear-gradient(-45deg, rgba(183, 10, 128, 0.67), rgba(33, 64, 154, 0.71), rgba(4, 30, 45, 1), );
        width: 48rem;
        height: 100vh;
    }

    .find-account-right-container {
        display: grid;
        grid-template-rows: 5rem min-content;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .back-button {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            margin-left: 2rem;
            cursor: pointer;
            width: min-content;
            color: $white;
        }

        .right-container-align-center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 55.2rem;
            height: 100vh;

            .logo {
                height: 4rem;
                width: 4rem;
                margin: 2rem auto;
                opacity: 0.7;
            }

            .account-text {
                margin: 0 0 1rem 0;
            }

            .account-sub-text {
                margin-bottom: 2rem;
                color: $dark-3;
                text-align: center;
            }

            .account-body-container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                gap: 1rem;

                .account-field-name {
                    margin-bottom: 0.5rem;
                    margin-top: 1rem;
                }

                .account-next-button {
                    color: $white;
                    width: 100%;
                    // height: 100%;
                    border-radius: 20rem;
                    margin-top: 5rem;
                    position: relative;
                }
            }
        }

        @media only screen and (max-width: $xs) {
            .right-container-align-center {
                width: unset;
                padding: 0 1rem;
            }
        }
    }
}

@media only screen and (max-width: $lg) {
    .find-account-left-container {
        display: none;
    }
}

@media only screen and (max-width: $md) {
    .find-account-container {
        flex-direction: column;
    }
}