@import '../../../styles/colors.scss';

/* Extra small devices (portrait phones, less than 576px) */
/* No media query since this is the default in Bootstrap */

/*  ================= Banner Section ================= */
.subscribe-sec {
  position: relative;
  width: 100%;
  min-height: 1024px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* border-radius: 0 0 30px 30px; */
  overflow: hidden;
  background-color: $dark-1;
  margin: auto;

  @media only screen and (max-width:1000px){
    .line-break{
      display: none;
    }
  }
}

.subscribe-sec .h1 {
  background-color: $grey-4;
  background-image: linear-gradient(90deg, $grey-4, $accent-3);
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.subscribe-sec .subtitle-style {
  color: $white;

  margin-bottom: 100px;
  max-width: 93.6rem;
}

.subscribe-sec span {
  color: $primary-brand-4;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  

  

  .subscribe-sec img.mobile {
    display: none;
  }

  .subscribe-sec img.desktop {
    display: block;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
