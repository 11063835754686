@import '../../../../styles/colors.scss';

.alert-container-error {
    width: 100%;
    background-color: #08293C;
    border: none;
    border-radius: 0.8rem;
    height: 5.6rem;
    display: flex;
    align-items: center;

    .icon-text {
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon {
            width: 2.4rem;
            height: 2.4rem;
        }
    }
}

.alert-container {
    width: 100%;
    border: none;
    border-radius: 0.8rem;
    height: 5.6rem;
    display: flex;
    align-items: center;

    .icon-text {
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon {
            width: 2.4rem;
            height: 2.4rem;
        }
    }
}

.alert-danger {
    color: $error;
}

.alert-success {
    color: green;
}