@import '../../../../styles/colors.scss';

.service-product-assessment-container{
    .section-02-container{
        display: flex;
        flex-direction: column;
        gap: 5rem;
        margin-top: 10rem;

        .text-area-container{
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
        
    }
}