@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

.accounts-container {
    .blue-01 {
        display: flex;
        flex-direction: column;
        height: 81.2rem;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        background: radial-gradient(142.09% 284.05% at 51.6% -184.05%, $border-gradient-end 0%, $dark-1 85.46%, $dark-1 100%);
        z-index: 1;
        border-bottom-left-radius: 3rem;
        border-bottom-right-radius: 3rem;

        .subtitle-heading {
            color: $primary-brand-3;
        }

        .work-mainHeading {
            background: linear-gradient(92deg,
                    $white 9.82%,
                    rgba($white, 0) 160.12%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .body-paragraph {
            background: linear-gradient(92deg,
                    $white 9.82%,
                    rgba($white, 0) 160.12%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            max-width: 55.2rem;
            text-align: center;
        }
    }

    .account-section-containers {
        margin-top: 15rem;
    }

    .blue-02 {
        height: 40rem;
        background: radial-gradient(122.71% 245.32% at 50% 245.32%,
                $secondary-brand-3 0%,
                $dark-1 85.46%,
                $dark-1 100%);
    }
}