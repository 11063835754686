@import '../../../../styles/colors.scss';
@import '../../../../styles/breakpoints.scss';

.slider-image {
  // position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.96) 94.88%) !important;
  opacity: 0.7;
  width: 2000px;
  height: 960px;
  object-fit: cover;

  @media only screen and (max-width:$xs) {
    height: 832px;
    width: 428px !important;
  }

}

@media only screen and (max-width:$md) {
  .guid-your-vision {
    padding: 0 !important;
  }

  .landing-page-wrap .guid-your-vision::before {
    background: transparent !important;
  }
}

.slick-dots {
  li {
    width: 8px !important;
  }
}

.slick-dots {
  li {
    button {
      height: 70px;

      &::before {
        font-size: 0.4rem;
        color: $white;
        opacity: 50%;
      }
    }
  }
}

.slick-dots {
  li.slick-active {
    button:before {
      font-size: 0.8rem;
      color: $white;
    }
  }
}

.text-containerr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  position: absolute;
  bottom: 20rem;
  left: 8rem;


  @media only screen and (max-width:$md) {
    left: 1.6rem;
    bottom: 15rem;
  }

  .slider-text {
    max-width: 55.2rem;
    padding-right: 1.6rem;
  }

  .slider-subtext {
    span {
      color: $dark-3;
    }
  }
}

.slick-slider {
  z-index: -10;
  margin-top: -25px !important;

}

.custom-dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: $white;
  opacity: 50%;
  border-radius: 50%;
  display: inline-block;
  margin: 2rem 0 0 0;
  padding: 0;
  z-index: 1;

}

.slick-active .custom-dot {
  width: 0.8rem;
  height: 0.8rem;
  opacity: 100%;
  background-color: $white;
}


.slick-dots li {
  width: 10px;
}