@import '../../../../styles/colors.scss';
@import '../../../../styles/breakpoints.scss';

.creator-onboarding-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: 100%;
    gap: 5rem;

    @media only screen and (max-width:$md) {
        grid-template-columns: 100%;

    }

    .creator-onboarding-body-left {

        .co-main-heading {
            margin-top: 7rem;
            background: linear-gradient(103.55deg, $white 0%, rgba($white, 0) 147.41%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media only screen and (max-width:$md) {
                margin-top: 5rem;
            }
        }

        .co-sub-heading {
            max-width: 45.6rem;
            background: linear-gradient(103.55deg, $white 0%, rgba($white, 0) 147.41%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 400;
        }

        .co-button {
            margin-top: 5rem;
        }
    }

    .creator-onboarding-body-right {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .gs-heading-01,
        .gs-heading-02 {
            border-bottom: 0.5px solid $border-gradient-start;
        }

        .gs-heading-01 {
            &:first-child {
                margin-top: 5rem;

                @media only screen and (max-width:$md) {
                    margin-top: 0;
                }
            }
        }

        .cobr-sub-heading {
            max-width: 45.6rem;
            margin-bottom: 7rem;
        }

        .cobr-main-heading {
            font-weight: 500;

        }
    }
}