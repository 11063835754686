@import 'src/styles/colors.scss';
@import 'src/styles/breakpoints.scss';

nav.navbar.simple-navbar {
    position: fixed;
    width: 100%;
    height: 80px;
    top: 0;
    right: 0;
    left: 0;
    padding: 0;
    background-color: unset !important;
    background: rgba($white, 0.2);
    box-shadow: 0 4px 30px rgba($grey-2, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-bottom: 1px solid rgba($white, 0.1);
    z-index: 10;
    box-shadow: 30px 40px 90px 0px $dark-1;
    backdrop-filter: blur(12px);


    .text-logo {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.2s, opacity 0.2s linear, transform 0.2s ease-in-out;
        transform: translateX(-10px);
        max-width: 100px;
    }

    .logo:hover+.text-logo {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
    }

    .container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}