@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

form button {
    position: relative;
}

.contact-form-container {
    display: grid;
    grid-template-rows: 8rem 1fr;
    width: 100%;


    .contact-form_navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        z-index: 999;
        position: sticky;
        top: 0;

        .navbar-brandd {


            .text-logo {
                position: relative;
                z-index: 999;
                visibility: visible;
                opacity: 1;
                margin-left: 1rem;
                max-width: 100px;

                @media only screen and (max-width:$md) {
                    visibility: hidden;
                    transition: visibility 0.2s, opacity 0.2s linear, transform 0.2s ease-in-out;
                    transform: translateX(-10px);
                    opacity: 0;
                }
            }

            .logo {
                margin-left: 2rem;

                &:hover+.text-logo {
                    visibility: visible;
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        .skip-button-container {
            position: relative;
            z-index: 999;
            margin-right: 2rem;

            .skip-button {
                color: $dark-3;

                border: none;
                outline: none;
                cursor: pointer;
                z-index: 999;

                &:hover {
                    color: $white;
                }
            }
        }
    }

    .contact-form-body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;


        .contact-form-left-container {
            max-height: 100vh;
            position: sticky;
            top: 2rem;
            z-index: 10;
            overflow-y: auto;


            @media only screen and (max-width:$md) {
                position: relative;
            }

            .cf-main-heading {
                margin-top: 8rem;
            }

            .cf-subtitle {
                font-weight: 400;
                margin: 1rem 0 2rem 0;
                max-width: 55.2rem;
            }

            .cf-features-container {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .cf-icon-text-container {
                    display: flex;
                    gap: 1rem;
                    color: $dark-4;


                    .circle-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 2rem;
                        height: 2rem;
                        border-radius: 100%;
                        background-color: $primary-brand-1;

                        .check-icon {
                            width: 1.2rem;
                            height: 1.2rem;
                            color: $dark-1;
                            stroke-width: 4;
                        }
                    }
                }
            }

            .cf-text-logos-container {
                border-width: 1px 0px 1px 0px;
                border-style: solid;
                border-color: $border-gradient-start;
                margin-top: 7rem;
                padding: 1rem 0;

                .cf-logos {
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                }

                @media only screen and (max-width:$md) {
                    display: none;
                }
            }

            @media only screen and (max-width:$md) {
                margin: 0 0.6rem;
            }
        }

        .contact-form-right-container {
            height: max-content;

            .cfr-form-container {


                .cfr-input-style {
                    margin-top: 1rem;
                }

                .cf-text-area {
                    height: 9.6rem;
                }

                .label-text-container {
                    margin-top: 2rem;
                }

                .cf-icon-text-agreement-error-container {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    margin-bottom: 4rem;

                    .cf-icon-text-agreement-container {
                        display: grid;
                        grid-template-columns: 2rem 1fr;
                        gap: 1rem;
                        color: $dark-4;
                        margin-top: 2rem;


                        .agreement-square-icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            border-radius: 0.5rem;
                            background-color: $primary-brand-1;

                            .check-icon {
                                width: 1.2rem;
                                height: 1.2rem;
                                color: $white;
                                stroke-width: 4;
                            }
                        }

                        .agreement-text {
                            max-width: 51.6rem;
                        }
                    }

                    .agreement-error {
                        color: $error;
                    }
                }

                .contact-our-team-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5rem;
                    position: relative;
                    color: $white;
                    height: 8rem;
                    width: 100%;

                    .cot-button{
                        min-width: 18.8rem;
                    }

                    @media only screen and (max-width:$md) {
                        margin-bottom: 20rem;
                    }
                }
            }

            @media only screen and (max-width:$md) {
                margin: 0 0.6rem;
            }
        }

        @media only screen and (max-width:$md) {
            grid-template-columns: 100%;
            gap: 7rem
        }
    }
}