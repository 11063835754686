@import '../../../../styles/colors.scss';
@import '../../../../styles/breakpoints.scss';

.half-image-text {
  margin-bottom: 120px;
  background-color: transparent;
  border-radius: 30px;

  @media only screen and (max-width:$md) {
    margin-bottom: 50px;
  }

  .row {
    .image-design {
      margin: 0;
      padding: 0 1.6rem !important;
      text-align: left !important;
    }

    img {
      border-radius: 30px;
      box-shadow: 0px 0px 40px 0.2px rgba($dark-4, 0.4);
    }

  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;


    .pink-text {
      color: $primary-brand-3;

    }

    .title-style {
      color: $white;
      display: flex;
      align-items: baseline;
      gap: 0.2rem;

    }

    .text-style {
      color: $accent-3;
      max-width: 32rem;

      span {
        color: $white;
      }
    }


    a {
      color: $grey-3;

      text-decoration: none;
    }
  }

  .d-link {
    position: relative;


    text-decoration: none;

    .arrow-icon {
      display: inline-block;
      margin-left: 4px;
      transition: margin-left 0.1s;
    }

    &:hover {


      .arrow-icon {
        margin-left: 10px;
      }
    }
  }

  .find-out-more-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
    background-color: rgba($white, 0.16);
    padding: 0.7rem 1.2rem;
    border-radius: 40rem;
    outline: none;
    border: none;
    max-width: 19.4rem;

    .next-button-text {
      color: $white;



    }

    .pink-circle-icon {
      width: 3.6rem;
      height: 3.6rem;
      border-radius: 50%;
      background-color: $primary-brand-1;
      display: flex;
      align-items: center;
      justify-content: center;

      .arrow-icon {
        color: $white;
      }
    }


    &:active {
      background-color: rgba($white, 0.16);
    }
  }
}

@media only screen and (max-width:$md) {
  .half-image-text {
    .text {
      .text-style {
        max-width: 100%;
      }
    }
  }
}