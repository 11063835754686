//COLOR PALLETE
// *****************************************************************

// Backgrounds
// Texts
$dark-1: #041e2d;
$dark-2: #07202f;
$dark-3: #818e96;
$dark-4: #c0c7ca;

// Buttons
// Focused/Active states
$primary-brand-1: #b70a80;
$primary-brand-2: #C947A0;
$primary-brand-3: #DA84BF;
$primary-brand-4: #EDC1DF;

// Links
// Secondary Buttons
$secondary-brand-1: #4700D8;
$secondary-brand-2: #7540E2;
$secondary-brand-3: #A37FEB;
$secondary-brand-4: #D1BFF5;


$tertiary-brand-1: #5584AC;
$tertiary-brand-2: #80A3C1;
$tertiary-brand-3: #A9C1D5;
$tertiary-brand-4: #A9C1D5;

// Accent color
// Hairlines
// Subtle backgrounds
$accent-1: #08293c;
$accent-2: #465e6d;
$accent-3: #83949d;
$accent-4: #c1c9ce;

// Title
$white: #ffffff;

$text-gradient: linear-gradient(103.55deg, $white 0%, rgba($white, 0) 147.41%);

$glowUp-gradient: linear-gradient(90deg, #E3926F 0%, #21409A 94.64%);

// Valid fields
// Success messages
$success-1: #31d0aa;
$success-2: rgba(49, 208, 170, 0.75);
$success-3: rgba(48, 209, 170, 0.5);
$success-4: rgba(49, 208, 170, 0.102);

// Backgrounds
// Texts
$grey-1: #bdcee0;
$grey-2: #cddae8;
$grey-3: #dee6ef;
$grey-4: #eef3f7;

// Outlined button
$border-gradient-start: #435661;
$border-gradient-end: #bdcee0;

// Invalid fields
// Error messages
$error: #f24073;