@import '../../../../styles/colors.scss';

form input {
    padding-right: 0;
}

.check-box-container {
    .form-check-input {
        width: 2rem;
        height: 2rem;
        margin-top: 0;
        vertical-align: middle;

    }

    .form-check-input:checked {
        background-color: $primary-brand-1;
        border: none;
    }

    .form-check-input[type=checkbox] {
        border-radius: 0.5rem;
    }
}