// Variables
$color-primary: #08293c;
$color-lightest-grey: rgba(189, 206, 224, 0.1);
$color-white: white;
$color-grey: #9d9daf; // Adjust this color as needed
$font-family: 'Heebo';

// Accordion Container
.accordion-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.title-icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
// Accordion Heading
.accordion-heading {
  width: 100%;
  word-wrap: break-word;
  margin-bottom: 64px;
}

// Accordion Design
.accordion-design {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Accordion Divider
.accordion-divider {
  // border: 0.5px solid $color-grey;
  background-color: $color-grey;
  height: 0.07px;
  width: 100%;
}

// Accordion Title Divider Container
.accordion-title-divider-container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 32px;
}

// Accordion Content
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  width: 100%;

  &.open {
    max-height: 100vh;
  }
}
.accordion-content {
  margin-bottom: 32px;
}
.content {
  height: max-content;
  color: #c0c7ca;
}
// Arrow Icon
.arrow-icon {
  transition: transform 0.2s ease;

  &.open {
    transform: rotate(-180deg);
  }
}

.accordion-title {
  color: #fff;
}
