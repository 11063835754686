@import 'src/styles/colors.scss';
@import 'src/styles/breakpoints.scss';

.navbar-toggler {
    width: 26px;
    height: 26px;
    position: relative;
    cursor: pointer;
    border: none;
    /* Remove default button border */
    background: none;
    /* Remove default button background */
    padding: 0;
    margin-right: 2rem;
    /* Remove default button padding */
}

.toggle-dots-icon {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 0.5rem;
}

.dot {
    width: 4px;
    height: 4px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
}

/* Initial positions of the dots */
.dot1 {
    top: 4px;
    left: 4px;
}

.dot2 {
    top: 4px;
    right: 4px;
}

.dot3 {
    bottom: 4px;
    left: 4px;
}

.dot4 {
    bottom: 4px;
    right: 4px;
}

.navbar-toggler.open .toggle-dots-icon .dot {
    border-radius: 0;
}

/* Transformations for the X shape */
.navbar-toggler.open .toggle-dots-icon .dot1 {
    transform: rotate(135deg);
    width: 30px;
}

.navbar-toggler.open .toggle-dots-icon .dot2 {
    transform: rotate(-135deg);
    width: 30px;
    opacity: 0;
}

.navbar-toggler.open .toggle-dots-icon .dot3 {
    transform: rotate(38deg);
    width: 30px;
    margin-bottom: 1.3rem;

}

.navbar-toggler.open .toggle-dots-icon .dot4 {
    transform: rotate(-38deg);
    width: 30px;
    margin-bottom: 1rem;
    opacity: 0;
}

nav.navbar.menu-navbar {
    position: fixed;
    width: 100%;
    height: 80px;
    top: 0;
    right: 0;
    left: 0;
    padding: 0;
    z-index: 999;
    background-color: unset !important;
    background: rgba($white, 0.2);
    box-shadow: 0 4px 30px rgba($grey-2, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-bottom: 1px solid rgba($white, 0.1);
    box-shadow: 30px 40px 90px 0px $dark-1;
    backdrop-filter: blur(12px);


    .navbar-toggler {
        border: none;
        color: $white;


    }


    .navbar-toggler:focus {
        box-shadow: none;
    }

    .text-logo {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.2s, opacity 0.2s linear, transform 0.2s ease-in-out;
        transform: translateX(-10px);
        max-width: 100px;
    }

    .logo:hover+.text-logo {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
    }

    .container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .collapse {
        justify-content: center;
    }

    .nav-link {
        color: $grey-2;
        text-align: center;
        padding: 0 !important;
        margin: 0 12px;
    }

    .nav-link.active,
    .nav-link.show,
    .nav-link:hover,
    .nav-link:focus {
        color: $white;
    }
}

.navigation-menu-collapse {
    display: none;
}

@media only screen and (max-width: $lg) {
    nav.navbar.menu-navbar {
        .container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .signup {
            display: none;
        }
    }

    .navigation-menu-collapse {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100vh;
        top: 80px;
        right: -100%;
        z-index: 10;
        backdrop-filter: blur(12px);
        transition: 0.2s ease-out;

        &.show {
            right: 0;

            .navbar-nav {

                .nav-item {
                    right: 0;
                }
            }
        }

        .navbar-nav {
            overflow: hidden;
            margin-top: 2rem;
            display: flex;
            justify-content: space-between;
            height: 100%;

            width: max-content;

            .nav-item:first-child {
                position: relative;
                right: 0;
            }

            .nav-item {
                position: relative;
                right: -100%;
                transition: 0.5s;
                width: max-content;

                .nav-link {
                    margin-left: 2rem;
                    padding: 10px 0 !important;
                    text-align: left;
                    color: $dark-3;


                    &:hover,
                    &:active {
                        color: $white;
                    }


                }
            }

            .signup-buttonss {
                margin-left: 2rem;
                margin-bottom: 11rem;
                display: flex;
                align-items: center;
                gap: 1.6rem;

                .me-1,
                .me-2 {
                    color: $white;
                }

                .me-2 {
                    display: inline-flex;
                    align-items: center;
                    gap: 0.4rem;

                }

            }
        }
    }
}