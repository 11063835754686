@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

.contact-form-confirmation-container {
    display: grid;
    grid-template-rows: 8rem 1fr;
    align-items: center;
    height: 100vh;

    .contact-form-confirmation_navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        z-index: 999;
        position: sticky;
        top: 0;

        .navbar-brandd {

            .text-logo {
                position: relative;
                z-index: 999;
                visibility: visible;
                opacity: 1;
                margin-left: 1rem;
                max-width: 100px;

                @media only screen and (max-width:$md) {
                    visibility: hidden;
                    transition: visibility 0.2s, opacity 0.2s linear, transform 0.2s ease-in-out;
                    transform: translateX(-10px);
                    opacity: 0;
                }
            }

            .logo {
                margin-left: 2rem;

                &:hover+.text-logo {
                    visibility: visible;
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        .skip-button-container {
            position: relative;
            z-index: 999;
            margin-right: 2rem;

            .skip-button {
                color: $dark-3;

                border: none;
                outline: none;
                cursor: pointer;
                z-index: 999;

                &:hover {
                    color: $white;
                }
            }
        }
    }

    .contact-form-confirmation-body {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        width: 100%;

        .cfc-main-heading {
            background: linear-gradient(103.55deg, $white 0%, rgba($white, 0) 147.41%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 700;
        }

        .cfc-sub-heading {
            max-width: 55.2rem;
            text-align: center;

            span {
                color: $primary-brand-3;
            }

            @media only screen and (max-width:$sm) {
                padding: 0 2rem;
            }
        }

        .cfc-button {
            color: $white;
        }
    }
}