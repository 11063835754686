@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

/* Extra small devices (portrait phones, less than 576px) */
/* No media query since this is the default in Bootstrap */
body {
  overflow-x: hidden;
  overflow-y: auto;
}

.styling {
  font-weight: 500;

  b {
    font-size: inherit;
  }
}

span {
  color: $primary-brand-3;
}

.lp-custom-slider {
  display: none;
  position: relative;
  margin-bottom: 80px;



  .slider-image {
    height: 488px;

  }

  .text-containerr {
    bottom: 2rem;
  }

  .slick-list {
    border-radius: 30px;
    margin: 0 1.6rem;
  }

  @media only screen and (max-width:$md) {
    display: block;
  }
}

.glow-up-names {
  display: block;

  @media only screen and (max-width:$md) {
    display: none;
  }
}

.custom-modal-container {
  height: 100vh;
  display: flex !important;
  align-items: center;
  z-index: 99999;
  overflow: hidden;

  .video-container {
    margin-top: 2rem;
    position: relative;
    box-shadow: 50px 50px 50px 0px rgba($dark-1, 0.5);
    border-radius: 20px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .video-styling {
      max-width: 100%;
      max-height: 100%;
      border-radius: 20px;
      border: none;
      outline: none;

    }

    .icon-style {
      transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
      color: $white;
      max-height: 15px;
    }

    .button-styless {
      position: absolute;
      top: 3.2rem;
      right: 3.2rem;
      background-color: rgba($dark-1, .5);
      border: 2px solid rgba($grey-4, 0.2);
      outline: rgba($grey-4, .1);
      width: 50px;
      height: 50px;
      border-radius: 100%;
      box-shadow: 5px 7px 18px rgba($grey-2, .1), 0 8px 21px rgba($grey-1, .15);
      z-index: 10;
      transition: all 0.3s ease;



      &:hover {
        background-color: $white;

        .icon-style {
          transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
          color: $dark-1;

          height: 15px;
        }
      }
    }

    .center-play-icon-container {
      position: absolute;
      transition: transform 0.3s ease;
      width: 8.8rem;
      height: 8.8rem;
      border-radius: 50%;
      background-color: rgba($dark-1, .5);
      box-shadow: inset 0 0 0 1px rgba($grey-4, .2);
      border: 2px solid rgba($grey-4, 0.2);

      z-index: 10;

      .play-icon-style {
        transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
        color: $white;
        height: 30px;
        fill: $white;
      }

      &:hover {
        transform: scale(1.04);
      }
    }
  }
}

.custom-modal-container-2 {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh;
  border-radius: 30px;
  z-index: 9999999999;
  overflow: hidden;

  .header-container {
    margin-top: 2rem;
    z-index: 9999999;
    position: relative;

    img {
      width: 1000px;
      height: 600px;
      object-fit: cover;
      border-radius: 30px;

      @media only screen and (max-width:$xl) {
        width: 800px;
      }

      @media only screen and (max-width:$lg) {
        width: 600px;
      }

      @media only screen and (max-width:$md) {
        width: 500px;
      }

      @media only screen and (max-width:$sm) {
        width: 100%;
      }
    }

    .header-modal {
      position: absolute;
      top: 2rem;
      margin-left: 4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .button-styless {
        background-color: rgba($dark-1, .5);
        border: 2px solid rgba($grey-4, 0.2);
        outline: rgba($grey-4, .1);
        width: 50px;
        height: 50px;
        border-radius: 100%;
        box-shadow: 5px 7px 18px rgba($grey-2, .1), 0 8px 21px rgba($grey-1, .15);
        z-index: 10;
        transition: all 0.3s ease;
        margin-right: 5rem;

        .icon-stylee {
          transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
          color: $white;
          max-height: 15px;
        }

        &:hover {
          transform: scale(1.2) !important; // Zoom in on hover

          .icon-stylee {
            transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
            max-height: 15px;

          }
        }
      }
    }

    .body-modal {
      position: absolute;
      bottom: 15rem;
      margin: 0 4rem;

      .bm-1-text {
        max-width: 60rem
      }

      .bm-2-text {
        margin-top: 2rem;
        color: $dark-3;
      }
    }

    .footer-modal {
      position: absolute;
      bottom: 5rem;
      width: 100%;

      .divider-line {
        background-color: $dark-3;
        border: 2px solid rgba($grey-4, 0.2);
        outline: rgba($grey-4, .1);
        height: 2px;
        border: none;
        margin: 0 4rem;
        z-index: 10;
      }

      .prev-next-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2rem 3rem 0 3rem;

        .prev-button,
        .next-button {
          color: $dark-3 !important;
          background-color: transparent;
          border: none;
          outline: none;

          span {
            color: $white;
          }
        }
      }
    }
  }
}


.landing-page-wrap {
  position: relative;

  /*  ================= Banner Section ================= */
  .banner-sec-landingPage {
    position: relative;

    .banner-sec {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      overflow: unset !important;
      background-color: $dark-1;
      margin: auto;
      max-width: 1720px !important;
      min-height: 650px;
      background: radial-gradient(122.71% 245.32% at 50% -145.32%,
          $secondary-brand-3 0%,
          $dark-1 85.46%,
          $dark-1 100%);

      &.banner-sec-subheading {
        max-width: 77.4rem;
      }

      .h1 {
        text-align: center;
        margin: 0;

        /* 100% */
        background: linear-gradient(92deg,
            $white 9.82%,
            rgba($white, 0) 160.12%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .description {
        padding-top: 215px;
        margin-bottom: 130px;
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        gap: 1.6rem;

        @media only screen and (max-width:$md) {
          padding-top: 150px;
        }

        .banner-para {
          margin-bottom: 3.2rem;
        }

        .sign-up-button {
          @media only screen and (max-width:$md) {
            margin: 0 !important;
          }
        }
      }

      img {
        width: 100%;
        position: relative;
        border-radius: 30px;
      }
    }
  }

  .video-section {
    margin-top: 8rem;
    background-color: $dark-1;

    .video-box {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 612px;
        height: 612px;
        flex-shrink: 0;
        border-radius: 612px;
        opacity: 0.24;
        background: $primary-brand-1;
        filter: blur(180.5px);
        left: -306px;
        top: -306px;

        @media only screen and (max-width:$md) {
          top: -100px;
        }
      }
    }

    .videoStyles {
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }
  }









  /*  ================= Creative Collaboration  ================= */

  .creative__collaboration {
    position: relative;
    padding-top: 160px;
    background-color: $dark-1;

    .cc-desktop {
      display: grid;

      @media only screen and (max-width:$md) {
        display: none !important;
      }
    }

    .cc-mobile-slider {
      display: none;

      @media only screen and (max-width:$md) {
        display: block;
        position: relative;
        z-index: 1;
        margin: 80px 0 !important;
      }
    }

    .cc-main-text {
      margin-bottom: 1.6rem;
    }

    .cc-subtitle-styling {
      max-width: 53.1rem;
      margin-bottom: 20px;
    }


    .grid-container {
      display: grid;
      grid-template-columns: repeat(3, min-content);
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 20rem;
      margin-top: 10rem;

      @media only screen and (max-width:$md) {
        margin-top: 4rem;
      }
    }

    @media only screen and (max-width: 1200px) {
      .grid-container {
        display: grid;
        grid-template-columns: repeat(2, min-content);
        align-items: center;
        justify-content: center;
        width: 360px;
        gap: 20px;
      }
    }

    @media only screen and (max-width: 800px) {
      .grid-container {
        display: grid;
        grid-template-columns: min-content;
        align-items: center;
        justify-content: center;
        width: 360px;
        gap: 20px;
      }
    }
  }

  /*  ================= The platform for creative collaboration Section  ================= */
  .gallery-view {
    position: relative;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin: 0 -13px 160px -13px;

    .gallery-image {
      flex-basis: 32%;

      img {
        object-fit: cover;
        height: auto;
        vertical-align: middle;
        border-radius: 5px;
      }
    }
  }

  /*  =================  Works of Art  ================= */
  .works-of-art {
    position: relative;
    border-radius: 30px;
    background:
      linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.96) 94.88%),
      url(../../../assets/images/video-bg-1.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    min-height: 960px;
    padding-top: 120px;
    width: 100%;
    z-index: 1;
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.96) 94.88%);


    .btn {
      padding: 0;
    }

    @media only screen and (max-width:$md) {
      height: 832px;
    }

    .subtitle-styling {
      display: block;
      max-width: 100%;
      width: 100%;
      padding-bottom: 2rem;
      margin-top: 32px;
      font-weight: 600;

      span {
        color: $accent-4;
        font-weight: 400;
      }

      @media only screen and (max-width:$md) {
        display: none;
      }
    }

    .subtitle-stylingg {
      display: none;
      max-width: 100%;
      width: 100%;
      padding-bottom: 2rem;
      margin-top: 32px;
      font-weight: 600;

      span {
        color: $accent-4;
        font-weight: 400;
      }

      @media only screen and (max-width:$md) {
        display: block !important;
        margin-top: 1.6rem !important;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      background-color: $dark-1;
      opacity: 60%;
      border-radius: 30px;
    }

    .row {
      position: relative;
      z-index: 1;
    }

    .watch-video-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 10px;
      background-color: transparent;
      border: none;
      outline: none;
      color: $white;
      border: none;
      background: none;

      @media only screen and (max-width:$md) {
        justify-content: flex-start;
        margin-bottom: 5rem;
      }

      .button-icon-container {
        width: 4.8rem;
        height: 4.8rem;
        background-color: $white;
        border-radius: 100%;
        display: flex;
        align-items: center;
        transition: all 0.3s ease;

        .image-button {
          display: block;
          margin: auto;

          .icon-style {
            fill: $dark-1;
            color: $dark-1;
            height: 15px;
          }
        }
      }

      &:hover {
        .button-icon-container {
          background-color: $dark-1;
        }

        .image-button {
          display: block;
          margin: auto;

          .icon-style {
            fill: $white;
            color: $white;
            height: 15px;
          }
        }
      }
    }

    .woa-mainHeading {
      display: none;

      @media only screen and (max-width:$md) {
        display: block;
      }
    }

    .woa-subHeading {
      font-weight: 400;
    }

  }

  /*  =================  Create Your Series  ================= */
  .create-your-series {
    position: relative;
    padding: 160px 0 80px 0;
    overflow-x: hidden;
    margin-top: -2rem;

    &::before {
      content: '';
      position: absolute;
      width: 450px;
      height: 400px;
      flex-shrink: 0;
      border-radius: 1585px;
      opacity: 0.24;
      background: $primary-brand-1;
      filter: blur(180.5px);
      left: 50%;
      transform: translateX(-50%);
      top: -170px;
    }

    .cys-subtitle {
      margin-bottom: 1.6rem;
    }

    .cys-subtitlee {
      max-width: 1128px;

      .break {
        @media only screen and (max-width:$md) {
          display: none;
        }
      }
    }

    .row {
      position: relative;
    }
  }

  /*  =================  Finding Your Team  ================= */
  .finding-your-team {
    position: relative;
    padding: 80px 0;

    .fyt-subtitle {
      margin-bottom: 1.6rem;

      span {
        color: $primary-brand-3;
      }
    }

    .fyt-mainText {
      margin-bottom: 1.6rem;
    }

    .fyt-subtitle-2 {
      margin-bottom: 4rem;

      span {
        color: $primary-brand-3;
      }
    }

    span {
      color: $accent-4;
    }

    .description {
      margin-bottom: 70px;


    }

    .team-points-style {
      margin-bottom: 20px;
    }

    .img-title {

      .img {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-self: center;
        object-fit: contain;

        img {
          object-fit: contain;
        }
      }

      div {
        margin-top: 10px;
      }
    }

  }

  /*  =================  Glow Up  ================= */
  .glow-up {
    padding: 80px 0;

    .gu-subtitle {
      font-weight: 400;
      margin-bottom: 1.6rem;
    }

    .gu-mainHeading {
      margin-bottom: 1.6rem;
    }

    b {
      font-size: inherit;
      font-weight: 700;
    }

    .h3 {
      font-size: 36px;
    }


    .description {
      margin-bottom: 108px;
    }

    .ah-music-sec {
      position: relative;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        position: relative;
        display: inline-block;
        letter-spacing: -3.84px;
        background: linear-gradient(90deg, $primary-brand-1 0%, $secondary-brand-1 94.64%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 15px;


        img {
          width: 380px;
          height: 0px;
          position: absolute;
          left: 100px; // within li container we placed it leaving 100px gap
          transform: translateY(-50%); //horizontal center
          transition: 0.2s;
          object-fit: cover;
          border-radius: 15px;
          overflow: hidden;
          opacity: 0;
          z-index: 1;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
          /* Add box shadow */

        }

        &:hover {
          cursor: pointer !important;
          filter: none;
          background-color: transparent;
          background: white;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          /* Change text color to white when hovered */

          img {
            height: 220px;
            opacity: 1;
          }

          &~li {
            background: $accent-2;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        @media only screen and (max-width:$xs) {
          img {
            width: 250px;
            object-fit: cover;
          }
        }
      }
    }

    .glow-up-subtitle-styling {
      max-width: 50rem;
    }

    .glow-up-subtitle-styling-2 {
      max-width: 26.6rem;
      margin-top: 1rem;
    }


  }

  /*  =================  The Glow  ================= */
  .the-glow {
    position: relative;
    padding: 80px 0;
    height: 792px;
    background: $dark-1;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-bottom: -30px;
    z-index: 1;

    @media only screen and (max-width:$md) {
      display: none;
    }

    .tg-subtitle {
      font-weight: 400;
      margin-bottom: 1.6rem;
      color: $primary-brand-3;
    }

    .tg-mainHeading {
      margin-bottom: 1.6rem;
    }

    .description {
      position: sticky;
      top: 15rem;
      z-index: 2;
    }

    .counter-sec {
      position: relative;
      margin-bottom: 70px;

      p {
        color: $primary-brand-3;
      }


      &:last-child {
        margin-bottom: 0px;
      }
    }

    .the-glow-subtext {
      max-width: 36.4rem;
    }
  }

  /*  =================  Meets Structure  ================= */
  .guid-your-vision {
    position: relative;
    padding: 160px 0 80px 0;
    overflow-x: hidden;

    .picture-text-section {
      margin-top: 4rem !important;
      margin-bottom: 4rem;
    }

    .gyv-image {
      width: 100%;
      max-height: 760px !important;
      object-fit: cover;
      border-radius: 30px;
    }

    .bas-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::before {
      position: absolute;
      content: '';
      width: 1585px;
      height: 496px;
      flex-shrink: 0;
      border-radius: 1585px;
      opacity: 0.24;
      background: $border-gradient-end;
      filter: blur(180.5px);
      top: -243px;
    }

    .d-link {
      position: relative;
      color: $grey-4;

      text-decoration: none;

      .link-button-styles {
        margin-top: 1rem;
      }

      i {
        display: inline-block;
        margin-left: 4px;
        transition: 0.1s;
      }

      &:hover {
        i {
          padding-left: 5px;
        }
      }
    }

    .description {
      margin-bottom: 40px !important;


    }

    .box {
      position: relative;
      display: block;
      width: 100%;
      margin-bottom: 4rem;

      .img-box {
        margin-bottom: 20px;
      }

      .link-text-style {
        margin-top: 1.6rem;
      }
    }

    .gyv-subtext-1 {
      margin-bottom: 1.6rem;
      font-weight: 400;
    }

    .gyv-mainText {
      margin-bottom: 1.6rem;
      max-width: 1128px;
      padding-right: 1.6rem;
      position: relative;

      .r-symbol {
        font-size: 2rem;
        color: $white;


      }
    }

    .gyv-subtext-2 {
      max-width: 78.4rem;
      margin-bottom: 1.6rem;
    }

  }

  /*  =================  Meets Structure  ================= */

  .meets-structure {
    position: relative;
    padding: 80px 0;

    .ms-mainText {
      margin-bottom: 4rem;
    }

    .h1 {
      padding: 0;
      margin: 0;
    }

    .heading-styles {
      margin-bottom: 1.6rem;
      font-weight: 400 !important;
      margin-top: 40px !important;

      &:first-child {
        margin-top: 0 !important;
      }
    }



    .structure-sec {
      margin-top: 10px;


      ul {
        position: relative;
        margin: 0 0 30px 0;
        padding: 0;

        li {
          position: relative;

          color: $dark-4;
          margin-bottom: 15px;
          list-style: none;
          padding-left: 30px;

          &::before {
            content: url('../../../assets/icons/circle-check-solid.png');
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }

  /*  =================  Game Changing Location  ================= */

  .game-changing-location {
    position: relative;
    padding: 40px 0;

    .gcl-subtitle {
      font-weight: 400 !important;
      margin-bottom: 1.6rem;
    }


    .gcl-subtext {
      max-width: 40.6rem;
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
    }

    .row {
      display: block;
    }

    .d-link {
      position: relative;
      color: $grey-4;

      text-decoration: none;

      .link-button-styles {
        margin-top: 1rem;
      }

      i {
        display: inline-block;
        margin-left: 4px;
        transition: 0.1s;
      }

      &:hover {
        i {
          padding-left: 5px;
        }
      }
    }
  }

  /*  =================  Haderbach  ================= */
  .haderbach-sec {
    position: relative;
    padding: 80px 0;
    border: '1px solid yellow';
    background:
      linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.96) 94.88%),
      url(../../../assets/images/haderbach-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 960px;
    border-radius: 30px;

    @media only screen and (max-width:$md) {
      min-height: 832px;
    }

    .hb-allText {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .hb-mainText {
      font-family: Koulen;
    }

    .hb-image {
      @media only screen and (max-width:$md) {
        width: 80% !important;
      }
    }

    .haderbach-text {
      color: $accent-4;
      display: block;

      @media only screen and (max-width:$md) {
        margin-bottom: 80px;
        display: none;
      }
    }

    .haderbach-textt {
      color: $accent-4;
      display: none;

      @media only screen and (max-width:$md) {
        margin-bottom: 80px;
        display: block;
      }
    }

    .hb-text {
      max-width: 74.4rem;
      margin: 1.6rem 0;
    }

    b {
      color: $white;
      font-size: inherit;
    }

    .row {
      align-items: end;

      .description {
        margin-bottom: 185px;

        @media only screen and (max-width:$md) {
          margin-bottom: 10rem !important;
        }
      }
    }


    @media only screen and (max-width:$md) {
      .row {
        .description {
          margin: 0 !important;
          padding: 0 !important;

        }

      }

      .hb-allText {
        position: absolute;
        bottom: 2rem;
        margin: 0 !important;
      }
    }

  }
}

@media (max-width: 992px) {
  .subscription-sec {
    min-height: 1024px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 30px 30px;
    background: radial-gradient(122.71% 245.32% at 50% -145.32%,
        $dark-4 0%,
        $dark-1 85.46%,
        $dark-1 100%);
  }

  .subscription-sec .row {
    justify-content: center;
    text-align: center;
  }

  .banner-sec-landingPage {




    .banner-sec img.mobile {
      display: none;
    }

    .banner-sec img.desktop {
      display: block;
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (max-width: 1024px) {
  .landing-page-wrap {
    .container {
      max-width: 900px;
    }

    .landing-page-wrap .banner-sec-landingPage .banner-sec .img-box::before {
      display: none;
    }

    .haderbach-sec {
      background-position: right;
    }
  }
}

@media only screen and (max-width: 991px) {
  .landing-page-wrap {
    * {
      text-align: left !important;
    }

    .slick-dots {
      text-align: center !important;
    }

    .row.justify-content-center {
      justify-content: left !important;
    }

    .container {
      max-width: 100% !important;
      padding: 0 16px !important;
    }





    .banner-sec {
      img {
        border-radius: 20px;
      }
    }

    section {
      .description {
        padding-top: 215px;
        margin-bottom: 80px !important;
      }
    }



    .creative__collaboration {
      position: relative;
      padding-top: 80px;
    }

    .gallery-view {
      gap: 15px;
      margin: 0 0px 80px 0px;

      .gallery-image {
        flex-basis: 100%;
      }
    }

    .works-of-art {
      background-position: right;
      background-size: cover;
      display: flex;
      align-items: flex-end;
    }

    .create-your-series {
      padding: 80px 0 80px 0;
    }

    section .description {
      padding-top: 0px;
    }

    .finding-your-team {
      padding: 0px;
    }

    .guid-your-vision {
      position: relative;
      padding: 80px 0 80px 0;
    }

    .meets-structure {
      padding: 0px 0;
    }

    .haderbach-sec img {
      margin-bottom: 40px;
    }

    .blue-section-styles {
      height: 380px;
    }

    .blue-section-styles .section-text .section-text-style {
      text-align: center !important;


    }
  }

}

.section-text-style {
  text-align: center !important;
  max-width: 75.7rem;

}