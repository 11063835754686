@import '../../../../styles/colors.scss';

// /* ================= Footer ================= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.footer-container {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  // border: 1px solid yellow;
  overflow-x: hidden;
  z-index: 10;
  position: absolute;
  width: 100%;
  margin-top: -25px;
  background-color: $dark-1;

  .container {
    --bs-gutter-x: 0
  }

  .title-icon {
    margin: 0 1.6rem
  }

  .logo {
    margin: 0 0 0 1.6rem;
  }
}

.footer1 {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 124px;
  padding-top: 96px;
  padding-bottom: 160px;

  // height: 446px;
  .logo-name {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 10.882px;
    height: 50px;
    // margin-right: 124px;
    align-items: center;
  }

  .logoImage1 {
    width: 37.308px;
    height: 38px;
    flex-shrink: 0;
  }

  .name {
    width: 116.588px;
    height: 26.917px;
    flex-shrink: 0;
  }

  .res-footer1 {
    display: none;
  }

  .footer1List {
    display: grid;
    grid-template-columns: repeat(5, max-content);
    list-style: none;
    gap: 24px;
    justify-content: flex-start;

    // height: 446px;

    .list {
      // border: 1px solid yellow;
      list-style: none;

      .listItem {
        margin-bottom: 16px;
      }
    }

    .listHeading {
      text-decoration: none;
    }

    .subListHeading {
      color: $primary-brand-3;
      text-decoration: none;
    }
  }
}

.footer2 {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  align-items: center;
  height: 120px;


  .footer2List {
    display: grid;
    grid-template-columns: repeat(6, max-content);
    list-style: none;
    gap: 12px;
    align-items: center;

    .listHeading {
      color: $primary-brand-3;

      text-decoration: none;
    }

    .logo-name {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      gap: 10.882px;
      height: 50px;
      // margin-right: 124px;
      align-items: center;
    }

    .logoImage2 {
      width: 19.2px;
      height: 19.2px;
      flex-shrink: 0;
    }
  }

  .logos {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 11px;

    .logoStyle {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }
}

.divider {
  border-top: 1px solid rgba($grey-1, 0.1);
  background: linear-gradient(90deg,
      rgba($grey-4, 0.01) -0.04%,
      rgba($grey-4, 0.01) 102.08%);
}

@media only screen and (max-width: 1100px) {
  .footer1 {
    grid-template-columns: 100%;
    gap: 0;
    padding-bottom: 96px;
    padding-top: 64px;

    .footer1List {
      display: none;
    }

    .res-footer1 {
      // display: block;
      margin-top: 32px;
      display: grid;
      grid-template-columns: 100%;
      gap: 32px;
    }
  }

  .footer2 {
    display: grid;
    grid-template-columns: min-content;
    justify-content: flex-start;
    align-items: flex-start;
    height: 320px;
    // border: 1px solid yellow;
    gap: 24px;
    padding-bottom: 34px;
    padding-top: 40px;
    margin-bottom: 150px;

    .footer2List {
      grid-template-columns: max-content;
    }

    .logos {
      display: grid;
      grid-template-columns: repeat(4, min-content);
      gap: 11px;
      margin-left: 30px;
    }
  }


  .accordion-list {
    list-style: none;
    color: $primary-brand-3;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .accordion-item {
      text-decoration: none;
    }
  }
}