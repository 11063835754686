@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

.sign-in-email-container {
    display: flex;

    .sie-left-container {
        background: linear-gradient(-45deg, rgba(183, 10, 128, 0.67), rgba(33, 64, 154, 0.71), rgba(4, 30, 45, 1), );
        width: 48rem;
        height: 100vh;
    }

    .sie-right-container {

        padding: 0;
        margin: 0;
        width: 100%;

        .sie-align-center {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100vh;
            margin: 0 auto;
            width: 55.2rem;


            .logo {
                height: 4rem;
                width: 4rem;
                margin: 2rem auto;
                opacity: 0.7;
            }

            .sie-main-text {
                margin: 0 0 2rem 0;
                text-align: center;
            }

            .signin-buttons {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                gap: 1rem;

                .icon-stylee {
                    height: 2.4rem;
                }

                .google-button,
                .apple-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    border-radius: 1.6rem;
                    background-color: $white;
                    color: $dark-1;
                    width: 100%;
                    position: relative;
                }

                .apple-button {
                    color: $dark-1;
                }
            }

            .divider-lines {
                margin: 2rem 0;
                width: 100%;

                .or-text {
                    text-align: center;
                    position: relative;
                }

                .or-text::before,
                .or-text::after {
                    content: "";
                    position: absolute;
                    border: 1px solid $accent-2;
                    height: 0.1rem;
                    width: 45%;
                    top: 50%;
                }

                .or-text::before {
                    left: 0;
                }

                .or-text::after {
                    right: 0;
                }
            }

            .sie-form-container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                gap: 1rem;

                .sie-field-name {
                    margin-bottom: 0.5rem;
                    margin-top: 1rem;
                }

                .sie-next-button {
                    color: $white;
                    width: 100%;
                    border-radius: 20rem;
                    margin-top: 2rem;
                    position: relative;
                }
            }

            .sie-fp-button {
                color: $white;
                width: 100%;
                border-radius: 20rem;
                margin-top: 2rem;
                position: relative;
            }

            .sie-last-text {
                .sie-text {
                    color: $dark-3;
                    margin-top: 1rem;
                }

                .link-style {
                    color: $white;
                    text-decoration: none;
                }
            }
        }

        @media only screen and (max-width: $xs) {
            .sie-align-center {
                margin: 1.6rem;
                width: unset;
            }
        }
    }
}

@media only screen and (max-width: $lg) {
    .sie-left-container {
        display: none;
    }
}