@import "../../../styles/colors.scss";
@import "../../../styles/breakpoints.scss";

.sign-up-container {
  display: flex;
  align-items: center;

  .link-style {
    color: $white;
  }

  .left-containerr {
    background: linear-gradient(-45deg, rgba(183, 10, 128, 0.67), rgba(33, 64, 154, 0.71), rgba(4, 30, 45, 1));
    width: 48rem;
    height: 100vh;
  }

  .right-container {
    display: grid;
    grid-template-rows: min-content;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;

    .align-center-code {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
      width: 55.2rem;

      .logo {
        height: 4rem;
        width: 4rem;
        margin: 2rem auto;
        opacity: 0.7;
      }

      .password-text {
        margin: 1rem 0 1rem 0;
      }

      .sub-text {
        margin-bottom: 2rem;
        color: $dark-3;
      }

      .body-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        gap: 1rem;

        .field-name {
          margin-bottom: 0.5rem;
          margin-top: 1rem;
        }

        .password-input {
          margin-bottom: 5rem;

          .helper-text {
            display: flex;
            gap: 2rem;
            align-items: center;
            margin-top: 1rem;

            .helper-success {
              display: none;
              font-family: Poppins;

              &.hp-success {
                display: block;
              }

              .check-icon {
                margin-right: 0.5rem;
                background-color: $success-2;
                border-radius: 100%;
                stroke: $dark-1;
              }
            }

            .helper-text-1,
            .helper-text-2 {
              display: flex;
              align-items: center;
              gap: 1rem;

              &.hidden {
                display: none;
              }

              .point-circle {
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 0.3rem;
                background-color: $primary-brand-1;
              }

              .hp-1-text {
                &.hp-checked {
                  text-decoration: line-through;
                }
              }
            }
          }
        }

        .link-subtext {
          margin-bottom: 5rem;
        }

        .linkStylee {
          color: $dark-4;
          text-decoration: none;
        }

        .agreement-text {
          margin-bottom: 2rem;
          color: $dark-3;

          .link-styling {
            color: $white;
            text-decoration: none;
          }
        }
      }

      .next-button-link {
        max-width: 55.2rem;
        width: 100%;
        margin-right: auto;
      }

      .next-button {
        color: $white;
        width: 100%;
        position: relative;
      }
    }

    @media only screen and (max-width: $xs) {
      .align-center-code {
        padding: 0 1rem;
        width: unset;

        .next-button-link {
          max-width: unset;
        }
      }
    }
  }
}

@media only screen and (max-width: $lg) {
  .left-containerr {
    display: none;
  }
}

@media only screen and (max-width: $md) {
  .sign-up-container {
    flex-direction: column;
  }
}
