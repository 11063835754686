@import "../../../styles/colors.scss";
@import "../../../styles/breakpoints.scss";

.sign-up-container-create-account {
  display: flex;
  height: 100vh;

  .left-container-create-account {
    background: linear-gradient(-45deg, rgba(183, 10, 128, 0.67), rgba(33, 64, 154, 0.71), rgba(4, 30, 45, 1));
    width: 48rem;
    height: 100vh;
  }

  .right-container-create-account {
    overflow: auto;
    display: grid;
    grid-template-rows: min-content;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;

    .create-account-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
      width: 55.2rem;

      .logo {
        height: 4rem;
        width: 4rem;
        margin: 20rem auto 2rem auto;
        opacity: 0.7;
      }
    }

    .create-account-text {
      margin: 0 0 0 0;
      text-align: center;
    }

    .input {
      margin: 2rem 0;
    }

    .date-of-birth-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .date-of-birth-subtext {
        color: $dark-3;
        max-width: 55.2rem;
        padding: 0;
        margin: 0;
      }
    }

    .month-date-year-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 4rem;

      .month,
      .day,
      .year {
        margin-bottom: 0.5rem;
      }
    }

    .button-next {
      width: 100%;
      position: relative;
      margin-bottom: 5rem;
    }
  }

  .error {
    color: $error;
    margin-top: 1rem;
  }

  .body-container {
    width: 100%;
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    .field-name {
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }

    .password-input {
      .helper-text {
        display: flex;
        gap: 2rem;
        align-items: center;
        margin-top: 1rem;

        .helper-success {
          display: none;
          font-family: Poppins;

          &.hp-success {
            display: block;
          }

          .check-icon {
            margin-right: 0.5rem;
            background-color: $success-2;
            border-radius: 100%;
            stroke: $dark-1;
          }
        }

        .helper-text-1,
        .helper-text-2 {
          display: flex;
          align-items: center;
          gap: 1rem;

          &.hidden {
            display: none;
          }

          .point-circle {
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 0.3rem;
            background-color: $primary-brand-1;
          }

          .hp-1-text {
            &.hp-checked {
              text-decoration: line-through;
            }
          }
        }
      }
    }

    .link-subtext {
      margin-bottom: 5rem;
    }

    .linkStylee {
      color: $dark-4;
      text-decoration: none;
    }
  }
  .agreement-text {
    margin-bottom: 2rem;
    color: $dark-3;

    .link-styling {
      color: $white;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: $lg) {
  .left-container-create-account {
    display: none;
  }
}

@media only screen and (max-width: $xs) {
  .sign-up-container-create-account {
    .right-container-create-account {
      .create-account-container {
        width: unset;
      }
    }
  }
}
