@import '../../../../styles/colors.scss';
@import '../../../../styles/breakpoints.scss';

.full-container {
    height: 100%;

    .divider {
        width: 100%;
        height: 0.2rem;
        background-color: rgba($grey-1, 0.2);
    }

    .divider-without-text {
        display: none;
    }

    .select-container {
        display: grid;
        align-items: center;
        justify-content: center;
        width: 100%;
        grid-template-columns: 33.6rem 1fr;
        margin: 2rem 0;

        .options {
            margin: 0 auto;
        }

    }

    .select-container-without-text {
        max-width: 100%;
        margin: 2rem 0;
    }

    .text-area {
        .select-text {
            width: 100%;
        }
    }

    .list-without-text {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        max-width: 100%;
        gap: 1.6rem;
        padding: 0;

        .listItem {
            width: max-content;
            height: 7.2rem;
            border-radius: 1.5rem;
            background-color: $accent-1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            color: $white;
            outline: none;
            border: none;

            &:hover,
            &:active,
            &.selected {
                background-color: $accent-2 !important;
            }

            .button-text-style {
                width: max-content;
                padding: 0 2rem;
            }

        }

        .listItem-with-top-text {
            width: 26.4rem;
            height: 9.6rem;
            border-radius: 1.5rem;
            background-color: $accent-1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            color: $white;
            outline: none;
            border: none;

            &:hover,
            &:active,
            &.selected {
                background-color: $accent-2 !important;
            }

            .button-text-style {
                width: 9.2rem;
            }

        }
    }

    .list {
        list-style: none;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        gap: 2rem;

        .listItem {
            width: 13.6rem;
            height: 7.2rem;
            border-radius: 1.5rem;
            background-color: $accent-1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            color: $white;
            outline: none;
            border: none;

            &:hover,
            &:active,
            &.selected {
                background-color: $accent-2 !important;
            }

        }
    }

    .small-screen-select-container {
        display: none;
    }

}

.modal-stylingg {
    margin-top: 10rem;

    .modal-form-container {
        background-color: $dark-1;
        display: flex;

        .button-styless {
            position: absolute;
            top: 3.2rem;
            right: 3.2rem;
            background-color: rgba($dark-1, .5);
            border: 2px solid rgba($grey-4, 0.2);
            outline: rgba($grey-4, .1);
            width: 50px;
            height: 50px;
            border-radius: 100%;
            z-index: 10;
            transition: all 0.3s ease;


            .icon-stylee {
                fill: $white;
                color: $white;

                height: 20px;
            }

            &:hover {
                background-color: $white;

                .icon-stylee {
                    transition: 0.3s fill cubic-bezier(0, 0.44, 0.6, 1);
                    color: $dark-1;
                    height: 20px;
                }
            }

        }

        flex-direction: column;
        width: 100%;
        height: 100%;
        border-radius: 1.6rem;

        .heading-Style {
            padding: 4.8rem 0;
            text-align: center;
        }

        .amount-input-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;


        }

        .input-button-container {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 0 5rem 7rem 5rem;
        }

    }
}

@media only screen and (max-width:$lg) {

    .full-container {
        .divider {
            display: none;
        }

        .select-container {
            display: none;
        }

        .select-container-without-text {
            display: none;
        }

        .small-screen-select-container {
            display: block;


            .all-options {
                .list-style {
                    display: flex;
                    flex-direction: column;

                    row-gap: 2rem;
                    margin: 4rem 0;
                    padding: 0;
                }

                .list-Item {
                    width: 100%;

                    border-radius: 1.5rem;
                    background-color: $accent-1;
                    height: 5.6rem;
                    color: $white;
                    outline: none;
                    border: none;

                    &:hover,
                    &:active,
                    &.selected {
                        background-color: $accent-2 !important;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width:$xs) {
    .small-screen-select-container {
        .text-area-container {
            max-width: 33.6rem;

            .select-text {
                font-size: 2.4rem;
                line-height: 3.2rem;
                width: max-content;
            }
        }
    }
}