@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

.skip-button,
.ad-details-container {
    &.first-ad-details {
        color: $border-gradient-end;
    }

    &.second-ad-details {
        color: $white;
    }

    &.third-ad-details {
        color: $dark-1;
    }

    &.fourth-ad-details {
        color: $white;
    }

    &.fifth-ad-details {
        color: $border-gradient-end;
    }
}

.full-container-ad {

    &.firstPage {
        background-color: $dark-1;
    }

    &.secondPage {
        background-color: $tertiary-brand-1;
        
    }

    &.thirdPage {
        background-color: $border-gradient-end;
    }

    &.fourthPage {
        background-color: $secondary-brand-1;
    }

    &.fifthPage {
        background-color: $dark-1;
    }

    &.sixthPage {
        background-color: $tertiary-brand-1;
    }

    .accountDetails_SuperProducer-container {
        display: grid;
        grid-template-rows: 8rem 1fr 8rem;
        align-items: center;
        width: 100%;
        height: 100vh;

        .accountDetails_navbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            z-index: 999;

            .navbar-brandd {

                .text-logo {
                    position: relative;
                    z-index: 999;
                    visibility: visible;
                    opacity: 1;
                    margin-left: 1rem;
                    max-width: 100px;

                    @media only screen and (max-width:$md) {
                        visibility: hidden;
                        transition: visibility 0.2s, opacity 0.2s linear, transform 0.2s ease-in-out;
                        transform: translateX(-10px);
                        opacity: 0;
                    }
                }

                .logo {
                    &:hover+.text-logo {
                        visibility: visible;
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }

            .skip-button-container {
                position: relative;
                z-index: 999;

                .skip-button {

                    border: none;
                    outline: none;
                    cursor: pointer;
                    z-index: 999;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }

        .accountDetails_body {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0;
            padding: 0;
            left: 0;

            .ad-details-container {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;

                .ad-image {

                    img {
                        width: 100%;
                        padding: 0 2rem;
                    }

                    .rive-style {
                        padding: 0 !important;
                        margin: 0 !important;

                        canvas {
                            height: 30rem !important;
                            width: 100% !important;


                        }
                    }
                }

                .ad-main-heading {
                    margin-top: 3.2rem;
                }

                .ad-sub-heading {
                    margin-top: 1rem;
                    max-width: 36rem;
                    text-align: center;
                }

                .step-dots {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 1rem;
                    margin-top: 2rem;

                    .dot {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);
                        cursor: pointer;

                        &.third-ad-dots {
                            background-color: rgba($dark-1, 0.2);

                            &.active {
                                background-color: $dark-1;
                                height: 0.8rem;
                                width: 0.8rem;
                            }
                        }

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    .two {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);
                        cursor: pointer;

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    .three {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);
                        cursor: pointer;

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    .four {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);
                        cursor: pointer;

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    .five {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);
                        cursor: pointer;

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    .six {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);
                        cursor: pointer;

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }


                }
            }

            .last-step-container {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding-bottom: 5rem;
                z-index: 999;

                .ad-boxes {
                    display: grid;
                    align-items: center;
                    justify-content: center;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 2rem;
                    margin-top: 4rem;
                    width: 100%;

                    .ad-box-1,
                    .ad-box-2 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        gap: 1rem;
                        width: 36rem;
                        height: 32.1rem;
                        background-color: transparent;
                        border: 1px solid rgba(255, 255, 255, 1);
                        border-radius: 1.6rem;
                        cursor: pointer;
                        color: $white;

                        &:hover {
                            transform: scale(1.02) !important; // Zoom in on hover
                        }

                        .ad-box-list {
                            list-style: none;
                            text-align: center;

                            .ad-list-item {
                                margin: 0.5rem 0;
                            }
                        }

                        @media only screen and (max-width:$md) {
                            width: 36rem;
                        }

                        @media only screen and (max-width:$sm) {
                            width: 30rem;
                        }

                        @media only screen and (max-width:$xs) {
                            width: auto !important;
                        }
                    }

                    @media only screen and (max-width:$md) {
                        grid-template-columns: 1fr;
                        margin-top: 2rem;

                    }

                }

                .step-dots {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 1rem;
                    margin-top: 2rem;

                    .dot {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);

                        &.third-ad-dots {
                            background-color: rgba($dark-1, 0.2);

                            &.active {
                                background-color: $dark-1;
                                height: 0.8rem;
                                width: 0.8rem;
                            }
                        }

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    .two {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    .three {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    .four {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    .five {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    .six {
                        height: 0.4rem;
                        width: 0.4rem;
                        border-radius: 100%;
                        background-color: rgba($grey-1, 0.2);

                        &.active {
                            background-color: $white;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }
                }

            }
        }

        .accountDetails_footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            bottom: 0;
            left: 0;
            width: 100%;

            .previousAndNextButtons-container {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 2rem;


                .ad-previous-button,
                .ad-next-button {
                    color: $white;
                    border: none;
                    outline: none;
                    background-color: transparent;

                    &.third-ad-details {
                        color: $dark-1;
                    }


                    &:disabled {
                        color: $dark-3;
                        cursor: not-allowed;
                    }
                }
            }
        }

        @media only screen and (max-width:$md) {
            .accountDetails_navbar {
                .logo {
                    margin-left: 1rem;
                }

                .skip-button-container {
                    margin-right: 1rem;
                }
            }

            .accountDetails_footer {
                .previousAndNextButtons-container {
                    margin-right: 1rem;
                }
            }
        }
    }
}