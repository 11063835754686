@import '../../../../styles/colors.scss';
@import '../../../../styles/breakpoints.scss';

.expertise-selection-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    width: 100%;
    height: 100%;

    @media only screen and (max-width:$md) {
        grid-template-columns: 100%;

    }

    .expertise-selection-left {
        position: sticky;
        top: 0;
        z-index: 10;
        height: 100%;
        overflow-y: auto;
        max-height: 100vh;

        @media only screen and (max-width:$md) {
            position: relative;

        }

        .es-sub-title-1 {
            margin-top: 10rem;
            color: $primary-brand-3;
            font-weight: 400;
        }

        .es-main-heading {

            background: linear-gradient(103.55deg, $white 0%, rgba($white, 0) 147.41%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 1rem 0;

        }

        .es-sub-title-2 {
            max-width: 45.6rem;
            background: linear-gradient(103.55deg, $white 0%, rgba($white, 0) 147.41%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 400;

        }

        .es-button {
            margin-top: 5rem;
        }

        .previous-dot-next-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 4rem;
            max-width: 45.6rem;


            .previous-button {
                color: $white;
                border: none;
                outline: none;
            }

            .next-button {
                color: $white;
                border: none;
                outline: none;
            }

            .step-dots {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;

                .dot {
                    height: 0.4rem;
                    width: 0.4rem;
                    border-radius: 100%;
                    background-color: rgba($grey-1, 0.2);
                    cursor: pointer;

                    &.third-ad-dots {
                        background-color: rgba($dark-1, 0.2);

                        &.active {
                            background-color: $dark-1;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    &.active {
                        background-color: $white;
                        height: 0.8rem;
                        width: 0.8rem;
                    }
                }

                .two {
                    height: 0.4rem;
                    width: 0.4rem;
                    border-radius: 100%;
                    background-color: rgba($grey-1, 0.2);
                    cursor: pointer;

                    &.active {
                        background-color: $white;
                        height: 0.8rem;
                        width: 0.8rem;
                    }
                }

                .three {
                    height: 0.4rem;
                    width: 0.4rem;
                    border-radius: 100%;
                    background-color: rgba($grey-1, 0.2);
                    cursor: pointer;

                    &.active {
                        background-color: $white;
                        height: 0.8rem;
                        width: 0.8rem;
                    }
                }

                .four {
                    height: 0.4rem;
                    width: 0.4rem;
                    border-radius: 100%;
                    background-color: rgba($grey-1, 0.2);
                    cursor: pointer;

                    &.active {
                        background-color: $white;
                        height: 0.8rem;
                        width: 0.8rem;
                    }
                }

                .five {
                    height: 0.4rem;
                    width: 0.4rem;
                    border-radius: 100%;
                    background-color: rgba($grey-1, 0.2);
                    cursor: pointer;

                    &.active {
                        background-color: $white;
                        height: 0.8rem;
                        width: 0.8rem;
                    }
                }
            }

            @media only screen and (max-width:$md) {
                display: none;
            }
        }


    }

    .expertise-selection-right {
        margin-bottom: 40rem;
        margin-top: 5rem;

        .search-bar-co {
            position: sticky;
            top: 2rem;

            @media only screen and (max-width:$md) {
                position: initial;
            }
        }

        .expertise-list {
            background-color: $dark-1;
            border: 1px solid $border-gradient-start;
            border-radius: 1.6rem;
            margin-top: 2rem;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0 2rem;

            .el-main-heading {
                font-weight: 400;
                margin-top: 3rem;
                color: $dark-4;
            }
        }
    }

    .expertise-selection-footer {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5.6rem;
        width: 100%;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid rgba($grey-1, 0.1);
        background: linear-gradient(90.19deg, rgba($dark-1, 0.01) -0.04%, rgba($dark-1, 0.01) 102.08%);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        z-index: 10;



        .previous-dot-next-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0;
            margin: 0 2rem;


            .previous-button {
                color: $white;
                border: none;
                outline: none;
            }

            .next-button {
                color: $white;
                border: none;
                outline: none;
            }

            .step-dots {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;

                .dot {
                    height: 0.4rem;
                    width: 0.4rem;
                    border-radius: 100%;
                    background-color: rgba($grey-1, 0.2);
                    cursor: pointer;

                    &.third-ad-dots {
                        background-color: rgba($dark-1, 0.2);

                        &.active {
                            background-color: $dark-1;
                            height: 0.8rem;
                            width: 0.8rem;
                        }
                    }

                    &.active {
                        background-color: $white;
                        height: 0.8rem;
                        width: 0.8rem;
                    }
                }

                .two {
                    height: 0.4rem;
                    width: 0.4rem;
                    border-radius: 100%;
                    background-color: rgba($grey-1, 0.2);
                    cursor: pointer;

                    &.active {
                        background-color: $white;
                        height: 0.8rem;
                        width: 0.8rem;
                    }
                }

                .three {
                    height: 0.4rem;
                    width: 0.4rem;
                    border-radius: 100%;
                    background-color: rgba($grey-1, 0.2);
                    cursor: pointer;

                    &.active {
                        background-color: $white;
                        height: 0.8rem;
                        width: 0.8rem;
                    }
                }

                .four {
                    height: 0.4rem;
                    width: 0.4rem;
                    border-radius: 100%;
                    background-color: rgba($grey-1, 0.2);
                    cursor: pointer;

                    &.active {
                        background-color: $white;
                        height: 0.8rem;
                        width: 0.8rem;
                    }
                }

                .five {
                    height: 0.4rem;
                    width: 0.4rem;
                    border-radius: 100%;
                    background-color: rgba($grey-1, 0.2);
                    cursor: pointer;

                    &.active {
                        background-color: $white;
                        height: 0.8rem;
                        width: 0.8rem;
                    }
                }
            }
        }
    }
}