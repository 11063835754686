@import '../../../../styles/colors.scss';

/* ========= Subscription Section ========= */
.subscription-sec {
  min-height: 1024px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 30px 30px;
  background: radial-gradient(122.71% 245.32% at 50% -145.32%,
      $border-gradient-end 0%,
      $dark-1 85.46%,
      $dark-1 100%);

  button.subscribe-btn {
    color: #FFF;
    font-family: Heebo;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 48px;
    background: #21409A;
    padding: 15px 32px;
    border: 1px solid #21409a;
    transition: 0.3s;
    outline: none;
    height: 64px;
    position: absolute;
    right: 0px;
  }

  button.subscribe-btn:hover {
    background-color: #b70a80;
  }
}

.headingStyle {
  margin-bottom: 10px;
}

.subscription-sec .row {
  justify-content: center;
  text-align: center;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .subscription-sec {
    min-height: 1024px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 30px 30px;
    background: radial-gradient(122.71% 245.32% at 50% -145.32%,
        $border-gradient-end 0%,
        $dark-1 85.46%,
        $dark-1 100%);
  }

  .headingStyle {
    background: linear-gradient(92deg,
        $white 9.82%,
        rgba($white, 0) 160.12%);
    margin-bottom: 2rem;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
  }

  .subscription-sec .row {
    justify-content: center;
    text-align: center;
  }
}




















section.parent {
  view-timeline-name: --section;
  --content-size: 100%;

  /* CTA Specific stuff goes here.... */
  .cta {
    /* Activates showing the CTA */
    animation: activate both linear, activate linear reverse;
    animation-timeline: --section, view();
    animation-range: entry, cover 50%;
  }

  /* Word */
  .cta span:nth-of-type(1) {
    opacity: var(--scale, 0);
    transition: opacity 0.2s 0.6s;
    white-space: nowrap;
  }

  /* The icon holder */
  .cta span:nth-of-type(2) {
    display: grid;
    place-items: center;
  }

  .cta .input-container {
    --elastic: linear(0, 0.4789 7.06%, 0.7612 12.38%,
        0.8693 15.13%, 0.9538 17.95%,
        1.0165 20.89%, 1.059 24.04%,
        1.0829 27.58%, 1.0884 31.64%,
        1.0769 36.44%, 1.0202 50.6%,
        1.0005 59.62%, 0.9945 70.6%, 1);
    display: grid;
    grid-template-columns: 0 36px;
  }

  @keyframes activate {
    0% {
      --active: 0;
    }

    100% {
      --active: 1;
    }
  }
}

/* Use style queries to dictate the timing, etc. */
@container style(--active: 0) {
  section.parent {
    .cta__content::before {
      scale: 0;
      opacity: 0;
      transition: scale 0.5s var(--back);
    }

    .cta .input-container {
      scale: 0;
      transition: scale 0.2s 0.7s, grid-template-columns 0.5s 0.2s var(--elastic);
    }

    .cta__content span:first-of-type {
      opacity: 0;
      transition: opacity 0.2s 0s;
    }

    .cta__content span:last-of-type {
      scale: 0;
      opacity: 0;
      transition: scale 0.2s, opacity 0.2s;
    }
  }
}

@container style(--active: 1) {
  section.parent {
    .cta__content::before {
      scale: 0.99;
      opacity: 1;
      transition: scale 0.5s var(--back);
    }

    .cta .input-container {
      scale: 1;
      transition: scale 0.2s 0s, grid-template-columns 0.5s 0.6s var(--elastic);
      grid-template-columns: var(--content-size, auto) 0px;
    }

    .cta__content span:first-of-type {
      opacity: 1;
      transition: opacity 0.2s 0.7s;
    }

    .cta__content span:last-of-type {
      scale: 1;
      opacity: 1;
      transition: scale 0.2s 0.4s, opacity 0.2s 0.4s;
    }
  }
}