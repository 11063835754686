@import '../../../styles/colors.scss';

.client-review-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .header {
        flex: 1;
        margin-top: 20rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .subtitle {
            color: $primary-brand-3;
        }

        .mainHeading {
            color: $white;
        }
    }

    .body {
        flex: 10;
        margin-bottom: 10rem;
    }

    .footer {
        margin-bottom: 2rem;

        .step-bar-container {

            .step-lines {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 0.5rem;

                .one {
                    height: 0.5rem;
                    width: 100%;
                    background-color: rgba($grey-1, 0.2);

                    &.active {
                        background-color: $white;
                    }
                }

                .two {
                    height: 0.5rem;
                    width: 100%;
                    background-color: rgba($grey-1, 0.2);

                    &.active {
                        background-color: $white;
                    }
                }

                .three {
                    height: 0.5rem;
                    width: 100%;
                    background-color: rgba($grey-1, 0.2);

                    &.active {
                        background-color: $white;
                    }
                }

                .four {
                    height: 0.5rem;
                    width: 100%;
                    background-color: rgba($grey-1, 0.2);

                    &.active {
                        background-color: $white;
                    }
                }

                .five {
                    height: 0.5rem;
                    width: 100%;
                    background-color: rgba($grey-1, 0.2);

                    &.active {
                        background-color: $white;
                    }
                }
            }

            .step-buttons {
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin: 2rem 0;

                .back-button {
                    outline: none;
                    border: none;

                    .back-button-text {
                        color: $accent-3;
                    }
                }

                .next-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.7rem;
                    background-color: rgba($white, 0.16);
                    padding: 0.7rem 1.2rem;
                    border-radius: 40rem;
                    outline: none;
                    border: none;

                    .next-button-text {
                        color: $white;
                    }

                    .pink-circle-icon {
                        width: 3.6rem;
                        height: 3.6rem;
                        border-radius: 50%;
                        background-color: $primary-brand-1;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .next-arrow-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}