@import '../../../../styles/colors.scss';

// UNIVERSAL STYLING
input {
  color: $dark-3 !important;

  &::placeholder {
    color: $dark-3 !important;
  }
}

textarea {
  color: $dark-3 !important;

  &::placeholder {
    color: $dark-3 !important;
  }
}

.helper-error-text {
  align-self: stretch;
  color: $error;
  font-family: Heebo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}

.input-disabled {
  border-radius: 16px;
  border: 1px solid var(--dark-041-e-2-d-75, $border-gradient-start);
}

.input-error {
  border-radius: 16px;
  border: 1px solid $error;
}

// TEXTFIELD STYLING
// ********************************************************************************
// whole container
.input-area {
  position: relative;
  flex: 1 0 0;
  background-color: $dark-1;
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  height: 5.6rem;
  outline: none;
  border: 1px solid $border-gradient-start;
  border-radius: 1.6rem !important;

  &:active,
  &:focus {
    border: 1px solid $white;
  }

  &.input-disabled {
    border: 1px solid $border-gradient-start;
  }

  &.input-error {
    border: 1px solid $error;
  }

}

.PhoneInputInput {
  background-color: $dark-1;
  border: none;
  outline: none;
  height: 4.6rem;

}

.PhoneInputCountry {
  margin-left: 2rem;
  padding-right: 2rem;
  border-right: 1px solid $dark-3;
}

.input-tel {
  background-color: $dark-1;
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 5.6rem;
  /* 150% */
  // border: none;
  outline: none;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid $border-gradient-start;


  &:active,
  &:focus {
    border: 1px solid $white;
  }

  &.input-disabled {
    // border-radius: 16px;
    border: 1px solid $border-gradient-start;
  }

  &.input-error {
    // border-radius: 16px;
    border: 1px solid $error;
  }

}

// text container
.input-textarea {
  align-self: stretch;
  height: 100%;
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 1rem;
  padding: 20px;
  background-color: transparent;
  border: 1px solid $border-gradient-start;
  border-radius: 1.6rem;
  outline: none;
  width: 100%;
  resize: none;
  height: 9.6rem;

  &:active,
  &:focus {
    border: 1px solid $white;
  }

  &.input-disabled {
    // border-radius: 16px;
    border: 1px solid $border-gradient-start;
  }

  &.input-error {
    // border-radius: 16px;
    border: 1px solid $error;
  }

}


.eye-icon {
  background-color: transparent;
  outline: none;
  border: none;
  color: $white;
  // margin: 0 2rem;
  position: absolute;
  right: 2rem;
  margin-top: 1rem;




  .icon {
    width: 2.4rem;
    height: 2.4rem;
    color: $dark-4;
  }

  &:hover {
    background-color: transparent;
  }
}