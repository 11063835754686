@import '../../../../styles/colors.scss';


// RADIO BUTTON STYLING
// **********************************************************************************
// default input white circle
.custom-radio-input {
  position: relative;
  width: max-content;
  height: max-content;
  border-radius: 48px;
  border: 0.1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  height: 20px;
  padding-left: 20px;
  z-index: 999;
  top: 0;
  left: 40%;
  cursor: pointer;
  opacity: 0;
}


// custom white circle styling
.custom-radio-checkmark {
  position: absolute;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #ffffff;
  transition: background-color 0.3s;

}

// pink circle styling
.custom-radio-checkmark::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: $primary-brand-1;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  opacity: 0;
  transition: opacity 0.3s;

}

// pink circle styling
.custom-radio-input:checked+.custom-radio-checkmark::before {
  opacity: 1;

}