@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';



.sign-up-container {
    display: flex;
    align-items: center;

    .link-style {
        color: $white;
        text-decoration: none;
        font-weight: 700;
    }

    .left-container {
        background: linear-gradient(-45deg, rgba(183, 10, 128, 0.67), rgba(33, 64, 154, 0.71), rgba(4, 30, 45, 1), );
        width: 48rem;
        height: 100vh;
    }

    .right-container {
        display: grid;

        grid-template-rows: min-content;
        // align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        margin: 0;

        .align-center {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100vh;

            .logo {
                height: 4rem;
                width: 4rem;
                opacity: 0.7;

            }

            .welcome-text {
                margin: 2rem 0 3rem 0;
            }

            .link-styling {
                text-decoration: none;
                color: $white;
            }

            .signup-buttons {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                gap: 1rem;

                .icon-stylee {
                    height: 2.4rem;
                }

                .google-button,
                .apple-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    border-radius: 1.6rem;
                    background-color: $white;
                    color: $dark-1;
                    position: relative;
                }

                .apple-button {
                    color: $dark-1;
                }
            }

            .divider-lines {
                margin: 2rem 0;
                width: 100%;

                .or-text {
                    text-align: center;
                    position: relative;
                }

                .or-text::before,
                .or-text::after {
                    content: "";
                    position: absolute;
                    border: 1px solid $accent-2;
                    height: 0.1rem;
                    width: 45%;
                    top: 50%;
                }

                .or-text::before {
                    left: 0;
                }

                .or-text::after {
                    right: 0;
                }
            }

            .create-button-link,
            .sign-in-button-link,
            .description {
                max-width: 55.2rem;
                width: 100%;
                margin-right: auto;

            }

            .create-button {
                width: 100%;
                color: $white;
                position: relative;
            }

            .sign-in-button {
                margin-top: 2rem;
                width: 100%;
                color: $white;
                position: relative;
            }

            .description {
                margin-top: 2rem;
            }

        }

        .last-text {
            margin-top: 4rem;
            margin-right: auto;

        }

        @media only screen and (max-width: $md) {



            .align-center {
                padding: 0 1rem;

                .create-button-link,
                .description {
                    max-width: unset;
                }
            }

        }

    }
}

@media only screen and (max-width: $lg) {
    .left-container {
        display: none;
    }
}

@media only screen and (max-width: $md) {
    .sign-up-container {
        flex-direction: column;
    }


}